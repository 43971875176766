import React, { memo, useEffect } from 'react';
import Grid from '@mui/material/Grid';
import './mentor-search-filters.scss';
import RadioButton from '../RadioButton';
import ReactTooltip from 'react-tooltip';
import { Taxonomy } from '../../types';
import Switch from '@mui/material/Switch';
import { useSystemTogglesContext } from '../../context/SystemToggles';

interface Props {
  taxonomy: Taxonomy[];
  addRemoveSkillId: any;
  selectedSkillList: number[];
  mentoringKind: 'ongoing_mentoring' | 'one_off_mentoring';
  changeMentoring: Function;
  setVisibleIdList: Function;
  visibleIdList: (number | string)[];
  partnerData?: any;
}

const MentorSearchFilters = ({
  taxonomy,
  addRemoveSkillId,
  selectedSkillList,
  mentoringKind,
  changeMentoring,
  partnerData,
  setVisibleIdList,
  visibleIdList,
}: Props) => {
  let SkillIdNamedictionary: any = [];

  taxonomy?.forEach((taxonomy) => {
    taxonomy.child?.forEach((childSkill) => {
      SkillIdNamedictionary[childSkill.id] = childSkill.name;
    });
  });

  const sortItems = (array: Taxonomy[] | undefined) => {
    return (
      array &&
      array.sort((a, b) =>
        a.order === b.order ? 0 : a.order > b.order ? 1 : -1
      )
    );
  };

  const makeVisible = (skillId: number | string | string) => {
    let newVisibleList: (number | string)[] = [];

    if (visibleIdList.includes(skillId)) {
      newVisibleList = [];
    } else {
      newVisibleList.push(skillId);
    }

    setVisibleIdList(newVisibleList);

    if (skillId > 13 && skillId !== 61 && skillId !== 67) {
      addRemoveSkillId(skillId);
    }
  };

  useEffect(() => {
    ReactTooltip.rebuild();
  }, [visibleIdList]);

  const { toggles } = useSystemTogglesContext();

  return (
    <Grid container className="mentor-search-filters">
      <ReactTooltip
        key="mentor-search-filters"
        className="tooltip"
        place={'top'}
        arrowColor={'#333333'}
        multiline={true}
      />

      <Grid className="skill-list">
        {(partnerData?.partner?.mentoring_kind || toggles.MENTORING_KIND) && (
          <Grid sx={{ mb: 3 }}>
            <h3>The type of support you are looking for?</h3>

            <div>
              Just one conversation to start{' '}
              <span
                className="question"
                data-tip={
                  'You will have a 60 minute session with a particular mentor to get ideas, feedback and answer questions. You can request sessions with as many different mentors as you want, for a range of opinions and advice. You can meet again with a particular mentor after the first session if you both agree to.'
                }
              >
                <img
                  width="5px"
                  height="8px"
                  src="/question.png"
                  alt="question"
                />
              </span>
              <Switch
                checked={mentoringKind === 'one_off_mentoring'}
                onChange={() => {
                  changeMentoring('one_off_mentoring');
                }}
              />
            </div>
            <div>
              An ongoing mentoring relationship{' '}
              <span
                className="question"
                data-tip={
                  'You are interested in having an ongoing relationship with a particular mentor to support you throughout their business journey.'
                }
              >
                <img
                  width="5px"
                  height="8px"
                  src="/question.png"
                  alt="question"
                />
              </span>
              <Switch
                checked={mentoringKind === 'ongoing_mentoring'}
                onChange={() => {
                  changeMentoring('ongoing_mentoring');
                }}
              />
            </div>
          </Grid>
        )}
        <h3>Select a topic to discuss in this session</h3>

        {sortItems(taxonomy)?.map((skill) => (
          <Grid key={`skill-${skill.id}`}>
            <Grid
              container
              display="inline-box"
              alignItems="center"
              gap="9px"
              className={`parent-skill ${
                visibleIdList.includes(skill.id) ? 'parent-selected' : ''
              }`}
              onClick={() => makeVisible(skill.id)}
              key={`skill-parent${skill.id}`}
              data-cy={`skill-parent-${skill.id}`}
            >
              {visibleIdList.includes(skill.id) ? (
                <img src="/minus.png" className="minus" alt="minus" />
              ) : (
                <img src="/plus.png" className="plus" alt="plus" />
              )}
              <span
                className={visibleIdList.includes(skill.id) ? 'selected' : ''}
              >
                {skill.name}
              </span>
              <span className="question" data-tip={skill.description}>
                <img
                  width={'5px'}
                  height="8px"
                  src="/question.png"
                  alt="question"
                  onClick={(e) => e.stopPropagation()}
                />
              </span>
            </Grid>

            {visibleIdList.includes(skill.id) && (
              <Grid
                container
                direction="column"
                key={`container-${skill.id}`}
                className="container"
              >
                {sortItems(skill.child)?.map((childSkill: any) => {
                  return (
                    <div
                      key={childSkill.id}
                      className="child-skill"
                      onClick={() => addRemoveSkillId(childSkill.id)}
                      data-cy={`child-skill-${childSkill.id}`}
                    >
                      <RadioButton
                        selected={selectedSkillList.includes(childSkill.id)}
                      />
                      <div
                        className={
                          selectedSkillList.includes(childSkill.id)
                            ? 'selected-label'
                            : ''
                        }
                      >
                        {childSkill.name}{' '}
                        <span
                          className="question"
                          data-tip={childSkill.description}
                        >
                          <img
                            width="5px"
                            height="8px"
                            src="/question.png"
                            alt="question"
                          />
                        </span>
                      </div>
                    </div>
                  );
                })}
              </Grid>
            )}
          </Grid>
        ))}
      </Grid>
    </Grid>
  );
};

export default memo(MentorSearchFilters);
