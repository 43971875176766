import React from 'react';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
} from '@mui/material';
import useWindowSize from 'hooks/useWindowSize';
import CloseIcon from '@mui/icons-material/Close';
import { DashboardFilledButton } from 'styling/buttons';

interface TimeSlotPopupAlertProps {
  open: boolean;
  handleClose: () => void;
  message: string;
  titlePopup:string;
}

const TimeSlotPopupAlert: React.FC<TimeSlotPopupAlertProps> = ({
  open,
  handleClose,
  message,
  titlePopup
}) => {
  const { width } = useWindowSize();
  const isMobile = width < 900;
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      sx={{
        padding: 0,
        '.MuiPaper-root': {
          padding: 0,
          borderRadius: '15px',
          background: ' #FFF',
          maxWidth:isMobile?'90vw':'600px',
          width:'auto',
          height: 'auto',
          display: 'inline-flex',
          flexDirection: 'column',
          alignItems: 'center',
          flexShrink: 0,
        },
      }}
    >
      <IconButton
        aria-label="close"
        onClick={handleClose}
        sx={{
          padding: '0px',
          marginRight: '20px',
          alignSelf: 'flex-end',
          marginTop: '20px',
        }}
      >
        <CloseIcon />
      </IconButton>
      <DialogTitle sx={{marginTop:'-20px', textAlign:'center', fontFamily:'Gilroy'}}>{titlePopup} </DialogTitle>
      <DialogContent>
        <DialogContentText sx={{ fontFamily: 'Gilroy', textAlign:'center' }}>
          {message}
        </DialogContentText>
      </DialogContent>
      <DialogActions
        sx={{
          display: 'flex',
          alignSelf: 'center',
          paddingBottom: '31px',
          width: ' 100%',
          justifyContent: 'center',
        }}
      >
        <DashboardFilledButton
          onClick={handleClose}
          sx={{
            width: '130px',
            height: '52px',
            padding: '12px 36px',
          }}
        >
          OK!
        </DashboardFilledButton>
      </DialogActions>
    </Dialog>
  );
};

export default TimeSlotPopupAlert;
