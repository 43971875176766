import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import MessageCenterBlock from './MessageCenterBlock';
import MessageCenterComposer from './MessageCenterComposer';
import MessageCenterHeader from './MessageCenterHeader';
import MessagesContainer from './MessagesContainer';

import MentorModal from 'components/MentorCard/MentorModal';
import { Mentor } from '../../../../types';

import './style.scss';
import api from 'api';

interface MessageCenterProps {
  info: any;
  accessPaused: boolean | undefined;
  clickBlock?: () => void;
  refetchMessage: () => void;
  sendMessage: (msg: string) => void;
  isMobile: boolean;
  clickBack: () => void;
  handleGetNextMessages: () => void;
  messages: any;
  hasNextMessages: boolean;
}

const MessageCenter = ({
  info,
  refetchMessage,
  accessPaused,
  clickBlock,
  sendMessage,
  isMobile,
  clickBack,
  handleGetNextMessages,
  messages,
  hasNextMessages,
}: MessageCenterProps) => {
  const navigate = useNavigate();
  const [mentor, setMentor] = useState<Mentor | undefined>(undefined);

  const openModal = async () => {
    const { results } = await api.searchMentors([info?.volunteer?.id], 1);

    if (results && results.length && results.length > 0) {
      setMentor(results[0]);
    }
  };

  return (
    <>
      <div className="message-center-container">
        <MessageCenterHeader
          info={info}
          clickBlock={clickBlock && clickBlock}
          blocked={info?.is_organisation_active}
          isMobile={isMobile}
          clickBack={clickBack}
          clickRequest={() =>
            navigate(
              `/volunteer-details?volunteer[]=${info?.volunteer?.id}&keyword=chat`
            )
          }
          clickImage={openModal}
        />
        <MessagesContainer
          messages={messages}
          hasNextMessages={hasNextMessages}
          handleGetNextMessages={handleGetNextMessages}
        />
        {info?.is_volunteer_active ||
        info?.is_organisation_active ||
        accessPaused ? (
          <MessageCenterBlock
            blockedByVol={info?.is_volunteer_active || accessPaused}
            blockedByMentee={info?.is_organisation_active}
            clickUnblock={clickBlock && clickBlock}
          />
        ) : (
          <MessageCenterComposer
            refetchMessage={refetchMessage}
            sendMessage={sendMessage}
            isMobile={isMobile}
            volunteerId={info?.volunteer?.id}
          />
        )}
      </div>

      {mentor && (
        <MentorModal
          mentor={mentor}
          modalOpen={Boolean(mentor)}
          closeModal={() => setMentor(undefined)}
          hideSelect
        />
      )}
    </>
  );
};

export default MessageCenter;
