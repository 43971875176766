import React, { useState, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { getUrlParam } from '../../utils/url';
import { getErrorMessageFromResponse } from '../../utils/error';
import useSendPasswordReset from '../../apiHooks/auth/useSendPasswordReset';
import useUpdatePassword from '../../apiHooks/auth/useUpdatePassword';
import InputWithLabel from '../InputWithLabel';
import Button from '../Button';
import styles from './reset-password.module.scss';
import { Helmet } from 'react-helmet';
import useSelectProfileData from '../../hooks/useSelectProfileData';
import ReCAPTCHA from 'react-google-recaptcha';

const ResetPassword = () => {
  const { partner } = useSelectProfileData();
  const code = getUrlParam('code') as string;
  const {
    sendPasswordReset,
    isLoadingSendPasswordReset,
    sendPasswordResetError,
  } = useSendPasswordReset();
  const { updatePassword, isLoadingUpdatePassword, updatePasswordError } =
    useUpdatePassword();
  const [email, setEmail] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [errorMessage, setErrorMessage] = useState<string>();
  const [successMessage, setSuccessMessage] = useState<string>();

  const [recaptchaValue, setRecaptchaValue] = useState<string>('');
  
  const navigate = useNavigate();

  let captcha: { reset: () => void; getValue: () => string};
  function boolfn() { return; }
  function strfn() { return ""; }
  captcha = { reset:boolfn, getValue: strfn };

  const site_key = process.env.REACT_APP_SITE_KEY ?? ""; 

  const setCaptchaRef = (ref: any) => {
    if (ref) {
      return captcha = ref;
    }
 };

 const resetCaptcha = useCallback(async () => {
  if (captcha === undefined) return;

  if (captcha.getValue() !== ''){
    captcha.reset();
    setRecaptchaValue('');
  }
  }, [captcha]);

  const handleChange = (value: any) => {
    setRecaptchaValue(value);
  };

  const subtitle = code
    ? 'Please enter a new password.'
    : 'Please enter your email address to receive the link to reset your password.';


  const updatePasswordWithCode = useCallback(async () => {
    if (!code || !password) return;
    setErrorMessage('');
    let redirectTimeout: ReturnType<typeof setTimeout>;
    try {
      await updatePassword({ code: code, password: password, token: recaptchaValue });
      setSuccessMessage('Password has been reset');
      redirectTimeout = setTimeout(() => {
        navigate('/login');
      }, 4000);
    } catch (err) {
      setErrorMessage(getErrorMessageFromResponse(err));
    }

    resetCaptcha()
    return () => !!redirectTimeout && clearTimeout(redirectTimeout);
  }, [code, navigate, password, updatePassword, recaptchaValue, resetCaptcha]);

  const sendPasswordResetEmail = useCallback(async () => {
    setErrorMessage('');
    try {
      await sendPasswordReset({ email: email, token: recaptchaValue, partnerId: partner?.id });
      setSuccessMessage('Please check your email for further instructions');
    } catch (err) {
      setErrorMessage(getErrorMessageFromResponse(err));
    }
    resetCaptcha()
  }, [email, sendPasswordReset, partner, recaptchaValue, resetCaptcha]);

  return (
    <>
      <Helmet>
        <title>Digital Boost Organisation Portal | Reset Your Password</title>
        <meta
          name="description"
          content="Reset your password for your Digital Boost Organisation account"
        ></meta>
      </Helmet>
      <div className={styles.container}>
        <div className={styles.card}>
          <h1 className={styles.title}>Reset Password</h1>

          <h3 className={styles.subtitle}>{subtitle}</h3>

          {(updatePasswordError || sendPasswordResetError || errorMessage) && (
            <div className={styles.error}>{errorMessage}</div>
          )}

          <div className={styles.subContainer}>
            <div className={styles.inputContainer}>
              {code ? (
                <>
                  <InputWithLabel
                    onInput={(e: React.ChangeEvent<HTMLInputElement>) => {
                      setPassword(e.target.value);
                      resetCaptcha();
                    }}
                    label={'Password'}
                    type={'password'}
                    placeHolder={'Enter your new password'}
                    className={styles.input}
                  />
                  <ReCAPTCHA
                    style={{ marginTop: "10px" }}
                    ref={(r) => setCaptchaRef(r) }
                    sitekey={site_key}
                    onChange={handleChange}
                  />
                  <Button
                    onClickFunction={() => updatePasswordWithCode()}
                    disabled={!!!password || recaptchaValue === ''}
                    type={'primary'}
                    text={'Update Password'}
                    className={styles.button}
                    loading={isLoadingUpdatePassword}
                  />
                </>
              ) : (
                <>
                  <InputWithLabel
                    onInput={(e: React.ChangeEvent<HTMLInputElement>) => {
                      setEmail(e.target.value?.trim());
                      resetCaptcha();
                    }}
                    label={'Email'}
                    type={'text'}
                    placeHolder={'Enter your email address'}
                    className={styles.input}
                  />
                  <ReCAPTCHA
                    style={{ marginTop: "10px" }}
                    ref={(r) => setCaptchaRef(r) }
                    sitekey={site_key}
                    onChange={handleChange}
                  />
                  <Button
                    onClickFunction={() => sendPasswordResetEmail()}
                    disabled={!!!email || recaptchaValue === ''}
                    loading={isLoadingSendPasswordReset}
                    type={'primary'}
                    text={'Reset Password'}
                    className={styles.button}
                  />
                </>
              )}
            </div>
            {!code && (
              <div className={styles.linkText}>
                Don’t have an account yet?{' '}
                <a href="/signup" className={styles.link}>
                  Signup here{' '}
                </a>
              </div>
            )}
            <span
              className={`${styles.successMessage} ${
                successMessage ? styles.successMessageVisible : ''
              }`}
              aria-hidden={!successMessage}
            >
              {successMessage ? successMessage : 'Placeholder'}
            </span>
          </div>
        </div>
      </div>
    </>
  );
};

export default ResetPassword;
