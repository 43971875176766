import useFetchPartner from 'apiHooks/partner/useFetchPartner';
import useSelectProfileData from 'hooks/useSelectProfileData';
import { useNavigate } from 'react-router-dom';
import { getAccessTokenCookie } from './auth';
import { getUrlParam } from './url';

export const useRedirectToPartnerWebsite = () => {
  const partnerId = getUrlParam('id') as string;
  const { partner, hasFinishedLoadingPartner } = useFetchPartner(
    parseInt(partnerId) || null
  );
  const loggedIn = getAccessTokenCookie();
  const { organisationProfile, hasFinishedLoadingOrganisationProfile } =
    useSelectProfileData();

  const currentURL = window.location.href;
  const url = new URL(currentURL);
  const hostnameParts = url.hostname.split('.');
  let currentSubdomain = hostnameParts[0];

  if (currentSubdomain === 'www') {
    currentSubdomain = hostnameParts[1];
  }

  const navigate = useNavigate();
  const encodedPath = encodeURIComponent(url.pathname + url.search + url.hash);
  // currentSubdomain !== 'localhost'&&
  if (
    currentSubdomain !== 'localhost' &&
    window.location.href.toString().indexOf('/redirect') === -1
  ) {
    // Check if the user is not logged in
    // Redirect the user to the partner's website
    if (
      !loggedIn &&
      hasFinishedLoadingPartner &&
      partner?.white_label &&
      partner.subdomain &&
      currentSubdomain !== partner.subdomain
    ) {
      navigate(`/redirect?community=${partner.subdomain}&path=${encodedPath}`);
    }
    // Check if the user is logged in and partner is whitelabel
    else if (
      hasFinishedLoadingOrganisationProfile &&
      organisationProfile &&
      loggedIn &&
      organisationProfile.partnerInfo?.white_label &&
      organisationProfile.partnerInfo?.subdomain
    ) {
      // Check if the current URL subdomain is different from the partner subdomain
      if (currentSubdomain !== organisationProfile.partnerInfo?.subdomain) {
        navigate(
          `/redirect?community=${organisationProfile.partnerInfo.subdomain}&path=${encodedPath}`
        );
      }
    } else if (
      loggedIn &&
      hasFinishedLoadingOrganisationProfile &&
      organisationProfile &&
      organisationProfile.partner === null &&
      currentSubdomain !== 'organisation'
    ) {
      if (window.location.href.indexOf('group-event-details') === -1)
        navigate(`/redirect?community=digitalboost&path=${encodedPath}`);
    } else if (
      loggedIn &&
      hasFinishedLoadingOrganisationProfile &&
      organisationProfile &&
      organisationProfile.partnerInfo &&
      !organisationProfile.partnerInfo?.white_label &&
      currentSubdomain !== 'organisation'
    ) {
      navigate(`/redirect?community=digitalboost&path=${encodedPath}`);
    }
  }
};
