import React, { memo, useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import ReactTooltip from 'react-tooltip';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import { Paper } from '@mui/material';
import RocketLaunchOutlinedIcon from '@mui/icons-material/RocketLaunchOutlined';
import { Helmet } from 'react-helmet';
import Cookie from 'js-cookie';

import './mentor-select.scss';

import BookingStatusBar from '../../components/BookingStatusBar';
import MentorSearchResults from '../../components/MentorSearchResults';
import MentorSearchFilters from '../../components/MentorSearchFilters';
import CardWithHeader from '../../components/CardWithHeader';
import ThankYouRegistration from 'pages/ThankYouRegistration';

import api from '../../api';
import apiInstance from '../../api/config';

import { statusNames } from '../../constants';
import { updateTaxonomy } from 'features/reduxStore/taxonomy/taxonomy';
import useSelectProfileData from '../../hooks/useSelectProfileData';
import { useGetPreferences } from 'pages/Preferences/functions';
import useFetchPartner from 'features/partner/hooks/useFetchPartner';
import useWindowSize from 'hooks/useWindowSize';
import useRedux from 'hooks/useRedux';

import { QuickMatchEmptyButton, DashboardFilledButton } from 'styling/buttons';
import { CustomTypography } from 'styling/generalStyling';

import { mobileBreakpoint, TabletBreakpoint } from 'styling/screenSizes';
import { useSelector } from 'react-redux';
import { selectSessionTitle } from 'features/reduxStore/sessionTitle';
import { useGetTaxonomy } from 'apiHooks/useGetTaxonomy';

const volunteerLimit = 10;

const MentorSelectPage = memo(() => {
  const {
    userProfile,
    userLocation,
    organisationProfile,
    hasFinishedFetchingProfileAndLocation,
    partner,
  } = useSelectProfileData();
  const preferencesData = useGetPreferences();

  const [hasLoadedVolunteers, sethasLoadedVolunteers] =
    useState<boolean>(false);
  const [loadedVolunteers, setLoadedVolunteers] = useState<any>([]);
  const [totalVolunteerResults, setTotalVolunteerResults] = useState<number>(0);
  const [limit] = useState(6);
  const [offset, setOffset] = useState(0);
  const [descriptionText, setDescriptionText] = useState<string>('');

  const [timeSlots, setTimeSlots] = useState([]);
  const [interestExpertise, setInterestExpertise] = useState('');

  const [partnerId, setPartnerId] = useState<null | number>(null);
  const [hasConsent, setHasConsent] = useState<boolean>(false);

  useEffect(() => {
    if (
      window.Cookiebot &&
      window.Cookiebot.consent?.necessary &&
      window.Cookiebot.hasResponse
    ) {
      setHasConsent(true);
      console.log('Consent has been given.');
    }
    const handleConsentReady = () => {
      setHasConsent(true);
    };

    window.addEventListener('CookiebotOnConsentReady', handleConsentReady);

    return () => {
      window.removeEventListener('CookiebotOnConsentReady', handleConsentReady);
    };
  }, []);
  const {
    taxonomyByParentFlat: taxonomy,
    taxonomyData,
    hasFinishedLoadingTaxonomy,
  } = useGetTaxonomy([], partnerId);
  const { dispatch } = useRedux();
  useEffect(() => {
    if (hasFinishedLoadingTaxonomy) {
      dispatch(updateTaxonomy(taxonomyData));
    }
  }, [hasFinishedLoadingTaxonomy, dispatch, taxonomyData]);
  if (partner && hasConsent) {
    Cookie.set('partner_id', partner.id?.toString(), {
      expires: 1,
    });
  }
  const [selectedSkillList, setSelectedSkillList] = useState<number[]>([]);
  const [selectedMentorsList, setSelectedMentorsList] = useState<number[]>([]);

  const [mentoringKind, setMentoringKind] = useState<
    'ongoing_mentoring' | 'one_off_mentoring'
  >('one_off_mentoring');

  const [visibleIdList, setVisibleIdList] =
    useState<number[]>(selectedSkillList);
  const hasSelectedAtLeastOneSkill = selectedSkillList.length > 0;
  const navigate = useNavigate();
  const location = useLocation();
  const getQueryParam = () => {
    return new URLSearchParams(location.search);
  };
  const query = getQueryParam();

  const loadMoreVolunteers = async () => {
    let newVolunteers = [...loadedVolunteers];

    let newOffset = offset + 6;

    const data = await searchMentors(
      limit,
      newOffset,
      selectedSkillList,
      mentoringKind,
      partnerId
    );
    const { results, count } = data || {};
    setTotalVolunteerResults(count);
    newVolunteers = newVolunteers.concat(results);
    setLoadedVolunteers(newVolunteers);
    setOffset(newOffset);
    sethasLoadedVolunteers(true);
  };

  const changeMentoring = async (
    newMentoringKind: 'ongoing_mentoring' | 'one_off_mentoring'
  ) => {
    let newOffset = 0;

    setMentoringKind(newMentoringKind);
    const data = await searchMentors(
      limit,
      newOffset,
      selectedSkillList,
      newMentoringKind,
      partnerId
    );
    const { results, count } = data || {};
    setTotalVolunteerResults(count);
    setLoadedVolunteers(results);
    setOffset(newOffset);
    sethasLoadedVolunteers(true);
  };

  const handleNext = () => {
    api
      .submitDraftBoostCall({
        selectedSkillList,
        selectedMentorsList,
        helpNeeded: descriptionText,
        partner: partnerId,
        timeSlots,
        interestExpertise,
        mentoringKind,
      })
      .then((response: any) => {
        organisationProfile?.first_name
          ? navigate('/select-availability')
          : navigate('/signup');
      })
      .catch((err: any) => console.log(err));
  };

  const chooseForMeNext = () => {
    api
      .submitDraftBoostCall({
        selectedSkillList,
        selectedMentorsList,
        helpNeeded: descriptionText,
        partner: partnerId,
        timeSlots,
        interestExpertise,
        mentoringKind,
        chooseForMe: true,
      })
      .then((response: any) => {
        organisationProfile?.first_name
          ? navigate('/select-availability')
          : navigate('/signup');
      })
      .catch((err: any) => console.log(err));
  };
  const addRemoveMentor = (mentorId: number) => {
    let newSelectedMentorsList = [...selectedMentorsList];

    if (newSelectedMentorsList.includes(mentorId)) {
      const index = newSelectedMentorsList.indexOf(mentorId);
      newSelectedMentorsList.splice(index, 1);
    } else if (newSelectedMentorsList.length !== volunteerLimit) {
      newSelectedMentorsList.push(mentorId);
    }

    setSelectedMentorsList(newSelectedMentorsList);
  };

  const addRemoveSkillId = async (skillId: number) => {
    let newSelectedSkillList: number[] = [];

    if (selectedSkillList.includes(skillId)) {
      const index = newSelectedSkillList.indexOf(skillId);
      newSelectedSkillList.splice(index, 1);
    } else {
      newSelectedSkillList.push(skillId);
    }

    // we want to remove all mentors when they change a skill beacause we want to limit to only 1 skill
    setSelectedMentorsList([]);

    const newOffset = 0;

    setOffset(0);

    if (hasLoadedVolunteers) {
      setSelectedSkillList(newSelectedSkillList);
      sethasLoadedVolunteers(false);
      const { results, count } = await searchMentors(
        limit,
        newOffset,
        newSelectedSkillList,
        mentoringKind,
        partnerId
      );
      setTotalVolunteerResults(count);
      setLoadedVolunteers(results);
      sethasLoadedVolunteers(true);
    }
  };

  const searchMentors = async (
    limit: number,
    offset: number,
    interestIds: any[] = [],
    mentoringKind: string = 'one_off_mentoring',
    partnerId: number | null
  ) => {
    let idsQuery = '';

    // temporary bug fix. ids 0 - 11 are parent ids. Removing parent ids from filter.
    // this is a bit of a hack and will need a better solution YOLO.

    interestIds.forEach((id) => {
      if (id >= 11) {
        idsQuery += `&interest=${id}`;
      }
    });

    if (partnerId) {
      idsQuery += `&partner_id=${partnerId}`;
    }

    if (userLocation?.country && hasFinishedFetchingProfileAndLocation) {
      idsQuery += `&country_code=${userLocation.country}`;
    }

    if (userProfile) {
      idsQuery += `&gender=${userProfile.gender}`;
    }

    if (mentoringKind) {
      idsQuery += `&mentoring_kind=${mentoringKind}`;
    }

    return apiInstance
      .get(
        `api/volunteer/search/volunteers/?limit=${limit}&offset=${offset}${idsQuery}`,
        {}
      )
      .then((response: any) => {
        return response.data;
      })
      .catch((err: any) => console.log(err));
  };

  useEffect(() => {
    let interestFromUrl = query.get('interest');

    let newSkillIds: number[] = [];
    if (interestFromUrl) {
      newSkillIds = [parseInt(interestFromUrl)];
      setSelectedSkillList([parseInt(interestFromUrl)]);
      taxonomyData?.forEach((taxonomyItem) => {
        if (
          interestFromUrl &&
          taxonomyItem.id === parseInt(interestFromUrl) &&
          taxonomyItem.parent
        ) {
          setVisibleIdList([taxonomyItem.parent]);
        }
      });
    }

    let mentoringKindQuery = query.get('mentoring_kind') as
      | 'ongoing_mentoring'
      | 'one_off_mentoring'
      | null;

    if (mentoringKindQuery) {
      setMentoringKind(mentoringKindQuery);
    } else {
      mentoringKindQuery = 'one_off_mentoring';
    }

    const getQueryParameter = () => {
      // if user has account, use that as a partner param
      if (organisationProfile) {
        if (organisationProfile?.partner) {
          return organisationProfile?.partner;
        } else {
          if (partner?.id) return partner?.id;
          else {
            let queryPartnerId = query.get('id');
            if (queryPartnerId && queryPartnerId !== null)
              return parseInt(queryPartnerId);
          }
        }

        return null;
      }
      // if no account use other logic, partner query param. etc
      else {
        if (partner?.id) return partner?.id;
        else {
          let queryPartnerId = query.get('id');
          if (queryPartnerId && queryPartnerId !== null)
            return parseInt(queryPartnerId);
        }
        return null;
      }
    };

    let queryPartnerId = getQueryParameter();

    if (queryPartnerId && queryPartnerId !== null) {
      setPartnerId(queryPartnerId);
      if (hasConsent)
        Cookie.set('partner_id', queryPartnerId.toString(), {
          expires: 1,
        });
    }

    const draftBoostCallUUID = Cookie.get('draftBoostCallUUID');

    // if you already have a draft then use that.

    if (draftBoostCallUUID) {
      apiInstance
        .get(`api/event/draftboostcallrequests/${draftBoostCallUUID}/`, {})
        .then(async (response: any) => {
          setSelectedSkillList(response.data.interests);
          setSelectedMentorsList(response.data.invited_volunteers);
          setDescriptionText(response.data.help_needed);
          setInterestExpertise(response.data.interest_expertise);

          let timeSlots: any = [];

          if (response.data.time_slots[0]) {
            timeSlots.push({
              start_time: response.data.time_slots[0].start_time,
            });
          }
          if (response.data.time_slots[1]) {
            timeSlots.push({
              start_time: response.data.time_slots[1].start_time,
            });
          }
          if (response.data.time_slots[2]) {
            timeSlots.push({
              start_time: response.data.time_slots[2].start_time,
            });
          }
          setMentoringKind(response.data.mentoring_kind);

          setTimeSlots(timeSlots);

          const data = await searchMentors(
            limit,
            offset,
            response.data.interests,
            mentoringKind,
            queryPartnerId
          );
          const { results, count } = data || {};
          setLoadedVolunteers(results);
          sethasLoadedVolunteers(true);
          setTotalVolunteerResults(count);
        })
        .catch((err: any) => console.log(err));
    } else {
      searchMentors(
        limit,
        offset,
        newSkillIds,
        mentoringKindQuery,
        partnerId
      ).then((data) => {
        const { results, count } = data || {};
        setTotalVolunteerResults(count);
        setLoadedVolunteers(results);
        sethasLoadedVolunteers(true);
      });
    }

    // eslint-disable-next-line
  }, [taxonomyData]);
  const { width = window.innerWidth } = useWindowSize();
  const isMobile = width && width < mobileBreakpoint;
  const isTablet =
    width && width <= TabletBreakpoint && width > mobileBreakpoint;
  useEffect(() => {
    if (selectedSkillList && selectedSkillList.length > 0)
      ReactTooltip.rebuild();
    if (hasLoadedVolunteers && loadedVolunteers?.length === 0)
      ReactTooltip.rebuild();
  }, [hasLoadedVolunteers, loadedVolunteers?.length, selectedSkillList]);

  let titleTag = 'Digital Boost | Choose your free business mentor';
  let metaDescription =
    'Select the topic you need help with and find your free Digital Boost business mentor';

  const partnerDataResult = useFetchPartner();
  if (partnerDataResult?.partner?.name) {
    titleTag =
      'Digital Boost | Choose your free business mentor | ' +
      partnerDataResult?.partner?.name;
    metaDescription =
      'Select the topic you need help with and find your free Digital Boost business mentor: Referred by ' +
      partnerDataResult?.partner?.name;
  }

  const session_title = useSelector(selectSessionTitle);

  return (
    <>
      <ReactTooltip place="top" id="mentors-tooltip" aria-haspopup="true">
        <div className="mentors-tooltip-title">Finding the right mentors</div>
        <div>
          All our experts are volunteers. If your preferred expert(s) are not
          available, we will find you someone else with similar experience. If
          you don’t want us to do this, let us know in your Mentoring
          Preferences after submitting.
        </div>
      </ReactTooltip>

      <Helmet>
        <title>{titleTag}</title>
        <meta name="description" content={metaDescription}></meta>
      </Helmet>
      {hasFinishedFetchingProfileAndLocation ? (
        <BookingStatusBar
          statusNames={statusNames}
          title={`Request a ${session_title} `}
          selectedIndex={1}
          hideProgressBar
          showButton
          buttonText={
            organisationProfile?.first_name
              ? 'Next: Select Availability'
              : 'Next: Log in or sign up'
          }
          buttonDisabled={
            selectedSkillList.length === 0 || selectedMentorsList.length === 0
          }
          clickNext={() => handleNext()}
        />
      ) : null}
      <Grid className="mentor-select">
        <Grid container direction="row" spacing={4} className="search">
          <Grid item xs={12} sm={12} md={4} lg={4}>
            <CardWithHeader
              className="mentor-select__subjects-column-select"
              hideHeader
            >
              {taxonomy.length !== 0 && (
                <MentorSearchFilters
                  addRemoveSkillId={addRemoveSkillId}
                  taxonomy={taxonomy}
                  selectedSkillList={selectedSkillList}
                  mentoringKind={mentoringKind}
                  changeMentoring={changeMentoring}
                  partnerData={partnerDataResult}
                  visibleIdList={visibleIdList}
                  setVisibleIdList={setVisibleIdList}
                />
              )}
            </CardWithHeader>
            <CardWithHeader
              className="mentor-select__subjects-column-tip"
              hideHeader
            >
              <img src="/tip_icon.svg" alt="tip" />
              <p>
                Focusing on one topic per session will help you get the most out
                of it. If you need help with additional topics, simply request
                more sessions after you submit this one.
              </p>
            </CardWithHeader>
          </Grid>
          <Grid item xs={12} sm={12} md={8} lg={8}>
            {hasSelectedAtLeastOneSkill ? (
              <>
                <CardWithHeader
                  className="mentor-select__mentors-explainer-container"
                  hideHeader
                >
                  <Grid
                    container
                    direction="row"
                    alignItems="flex-start"
                    justifyContent="space-between"
                    className="mentor-select__mentors-header"
                  >
                    {hasLoadedVolunteers &&
                    loadedVolunteers &&
                    loadedVolunteers?.length === 0 &&
                    selectedSkillList.length !== 0 ? (
                      <Grid />
                    ) : (
                      hasLoadedVolunteers && (
                        <Grid
                          container
                          xs={12}
                          direction="row"
                          alignItems="flex-start"
                        >
                          <Grid
                            direction="column"
                            className="mentors-select__header-column"
                          >
                            <Stack
                              direction="row"
                              alignItems="center"
                              justifyContent="space-between"
                            >
                              <h3>Find a mentor</h3>
                            </Stack>
                            {preferencesData?.privacy_preferences
                              ?.accept_substitutes ? (
                              <div className="mentors-select__header-column-wrapper">
                                <div className="helper-text">
                                  Browse and select potential mentors - we’ll
                                  check if they can help. <br />
                                  If they can’t, we’ll connect you to another
                                  great mentor with similar skills.
                                </div>
                                <span
                                  className="question"
                                  data-tip
                                  data-for="mentors-tooltip"
                                >
                                  <img
                                    width="5px"
                                    height="8px"
                                    src="/question.png"
                                    alt="question"
                                  />
                                </span>
                              </div>
                            ) : null}
                            <Paper
                              elevation={2}
                              sx={{
                                backgroundColor: '#F3F3F3',
                                display: 'inline-flex',
                                height: '85px',
                                columnGap: '20px',
                                justifyContent: 'space-between',
                                width: isTablet || isMobile ? '100%' : '60vw',
                                flexDirection:
                                  isTablet || isMobile ? 'column' : 'row',
                                alignItems: 'center',
                                paddingLeft: '10px',
                                paddingRight: '10px',
                                marginTop: '10px',
                                paddingBottom:
                                  isTablet || isMobile ? '10px' : '0',
                                paddingTop: isTablet || isMobile ? '10px' : '0',
                              }}
                            >
                              <CustomTypography
                                sx={{
                                  padding: 0,
                                  fontWeight: 'bold',
                                  fontSize:
                                    isTablet || isMobile ? '14px' : '16px',
                                  color: '#333',
                                }}
                              >
                                <RocketLaunchOutlinedIcon
                                  fontSize="medium"
                                  sx={{ marginBottom: '-4px' }}
                                />{' '}
                                Save time! Let us choose the best mentor for you
                              </CustomTypography>

                              <QuickMatchEmptyButton
                                variant="outlined"
                                sx={{
                                  fontSize:
                                    isTablet || isMobile ? '14px' : '16px',
                                }}
                                size="small"
                                onClick={() => {
                                  Cookie.set('system_pick', 'true', {
                                    expires: 1,
                                  });
                                  chooseForMeNext();
                                }}
                              >
                                Quick match
                              </QuickMatchEmptyButton>
                            </Paper>
                          </Grid>
                        </Grid>
                      )
                    )}
                  </Grid>
                </CardWithHeader>
                {hasLoadedVolunteers &&
                  loadedVolunteers &&
                  loadedVolunteers?.length !== 0 && (
                    <MentorSearchResults
                      hideSelect={selectedSkillList.length === 0}
                      mentors={loadedVolunteers}
                      addRemoveMentor={addRemoveMentor}
                      selectedMentorsList={selectedMentorsList}
                    />
                  )}
              </>
            ) : (
              hasLoadedVolunteers &&
              loadedVolunteers && (
                <Grid
                  container
                  direction="column"
                  alignItems="center"
                  justifyContent="center"
                  style={{ height: '70%', marginTop: '30px' }}
                  className="no-mentor-selected__container"
                >
                  <Stack direction="column" spacing={1}>
                    <CustomTypography
                      sx={{ fontSize: '25px', fontWeight: 'bold' }}
                    >
                      Select a topic to see mentors
                    </CustomTypography>
                    <img
                      src="/no-experts.png"
                      alt="select a topic"
                      height="350px"
                    />
                  </Stack>
                </Grid>
              )
            )}

            {hasLoadedVolunteers &&
              loadedVolunteers &&
              loadedVolunteers?.length === 0 &&
              selectedSkillList.length !== 0 && (
                <Grid
                  container
                  direction="column"
                  alignItems="center"
                  justifyContent="center"
                  style={{ height: 'calc(100% - 57px)' }}
                  className="mentor-select__no-mentors-container"
                >
                  <Stack direction="column" alignItems="center" spacing={4}>
                    <img
                      src="/no-experts.png"
                      alt="no mentors available"
                      height="350px"
                    />
                    <Stack direction="column" alignItems="center">
                      <h3>No mentors available</h3>
                      <span>
                        Please try a different topic or check back again soon.
                      </span>
                    </Stack>
                  </Stack>
                </Grid>
              )}

            {hasLoadedVolunteers &&
              loadedVolunteers &&
              loadedVolunteers?.length === 0 &&
              selectedSkillList.length === 0 && (
                <div> Please select a skill from filters </div>
              )}

            {!hasLoadedVolunteers && (
              <Grid
                container
                direction="column"
                alignItems="center"
                justifyContent="center"
                style={{ height: '100%' }}
              >
                <img
                  className="mentor-select__loading-spinner"
                  src="/loading.gif"
                  alt="loading"
                />
              </Grid>
            )}

            {hasSelectedAtLeastOneSkill &&
              hasLoadedVolunteers &&
              loadedVolunteers &&
              loadedVolunteers?.length < totalVolunteerResults && (
                <Grid container className="show-more">
                  <DashboardFilledButton
                    variant="contained"
                    sx={{ width: '100%' }}
                    onClick={() => loadMoreVolunteers()}
                  >
                    Show More
                  </DashboardFilledButton>
                </Grid>
              )}

            {hasLoadedVolunteers &&
              loadedVolunteers &&
              loadedVolunteers?.length > 0 &&
              selectedSkillList.length > 0 && (
                <Grid className="button-container-mobile-mentor">
                  <DashboardFilledButton
                    disabled={
                      selectedSkillList.length === 0 ||
                      selectedMentorsList.length === 0
                    }
                    onClick={() => handleNext()}
                  >
                    {organisationProfile?.first_name
                      ? ' Next: Availability'
                      : 'Next: Log in or sign up'}
                  </DashboardFilledButton>
                </Grid>
              )}
          </Grid>
        </Grid>
      </Grid>
    </>
  );
});

const MentorSelect = () => {
  Cookie.remove('system_pick');
  const { organisationProfile } = useSelectProfileData({ autoFetch: true });
  if (
    organisationProfile?.partner !== null &&
    organisationProfile?.is_partner_approved === false
  )
    return <ThankYouRegistration />;
  else return <MentorSelectPage />;
};

export default memo(MentorSelect);
