import React, { useEffect, useRef, useState } from 'react';
import './style.scss';
import FullScreenLoading from 'components/FullScreenLoading';
import api from 'api';
import RadioButton from 'components/RadioButton';
import { useGetTaxonomy } from 'apiHooks/useGetTaxonomy';
import ResourcesSearchFilters from 'components/ResourcesSearchFilters';
import SearchIcon from '@mui/icons-material/Search';
import _ from 'lodash';
import ResourceCard from 'components/ResourceCard';
import { DashboardFilledButton } from 'styling/buttons';
import useSelectProfileData from 'hooks/useSelectProfileData';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import ResourcesMobileFilters, {
  ApplyFiltersProps,
} from 'components/ResourcesMobileFilters';
import { Grid, Stack } from '@mui/material';
import { CustomTypography } from 'styling/generalStyling';
import YoutubeModal from 'components/YoutubeModal';

const CONTENT_TYPE = [
  {
    value: 'pdf',
    label: 'PDF',
  },
  {
    value: 'video',
    label: 'Video',
  },
  {
    value: 'article',
    label: 'Article',
  },
  {
    value: 'template',
    label: 'Template',
  },
];

const Resources = () => {
  const [mobileFilters, setMobileFilters] = useState(false);
  const [firstLoad, setFirstLoad] = useState(true);
  const [contentType, setContentType] = useState<string | undefined>(undefined);
  const [searchTopics, setSearchTopics] = useState('');
  const [filteredTaxonomy, setFilteredTaxonomy] = useState<any>([]);
  const [selectedSkillList, setSelectedSkillList] = useState<number[]>([]);
  const [visibleIdList, setVisibleIdList] =
    useState<number[]>(selectedSkillList);
  const [selectAll, setSelectAll] = useState(false);
  const [loading, setLoading] = useState(true);
  const [textValue, setTextValue] = useState('');
  const [textDebouncedValue, setTextDebouncedValue] = useState('');
  const [resources, setResources] = useState<any>([]);
  const [counter, setCounter] = useState(6);
  const [youtubeId, setYoutubeId] = useState(null);

  const { organisationProfile } = useSelectProfileData();

  const { taxonomyByParentFlat: taxonomy, hasFinishedLoadingTaxonomy } =
    useGetTaxonomy();

  useEffect(() => {
    if (hasFinishedLoadingTaxonomy && taxonomy.length !== 0 && firstLoad) {
      setFirstLoad(false);
      setFilteredTaxonomy(taxonomy);
    }
  }, [taxonomy, hasFinishedLoadingTaxonomy, firstLoad]);

  useEffect(() => {
    async function getResourcesFunction({
      contentType,
      textDebouncedValue,
      selectedSkillList,
    }: any) {
      try {
        const { data } = await api.getResources({
          contentType,
          textDebouncedValue,
          selectedSkillList,
        });

        if (data?.results) {
          setResources(data?.results);
          setLoading(false);
        }
      } catch (err) {
        setLoading(false);
      }
    }

    getResourcesFunction({
      contentType,
      textDebouncedValue,
      selectedSkillList,
    });
  }, [contentType, textDebouncedValue, selectedSkillList]);

  const handleSearchTaxonomy = (e: any) => {
    setSearchTopics(e.target.value);

    const array = [...taxonomy];
    const filtered = array
      .map((taxonomy) => {
        return (
          taxonomy?.child &&
          taxonomy?.child?.filter((child) =>
            child?.name?.toLowerCase().includes(e.target.value.toLowerCase())
          )?.length > 0 && {
            ...taxonomy,
            child: taxonomy?.child?.filter((child) =>
              child?.name?.toLowerCase().includes(e.target.value.toLowerCase())
            ),
          }
        );
      })
      ?.filter((e) => e);
    setFilteredTaxonomy(filtered);
  };

  const addRemoveSkillId = (skillId: number) => {
    let newSelectedSkillList: number[] = [];

    if (selectedSkillList.includes(skillId)) {
      const index = newSelectedSkillList.indexOf(skillId);
      newSelectedSkillList.splice(index, 1);
    } else {
      newSelectedSkillList.push(skillId);
    }

    setSelectAll(false);
    setSelectedSkillList(newSelectedSkillList);
  };

  const handleSelectAll = () => {
    setSelectAll(true);
    setSelectedSkillList([]);
    setVisibleIdList([]);
  };

  const debouncedSearch = useRef(
    _.debounce((value) => setTextDebouncedValue(value), 500)
  ).current;

  const handleChangeTextValue = (e: any) => {
    setTextValue(e.target.value);
    debouncedSearch(e.target.value);
  };

  const handleClickCard = async (resource: any) => {
    const url = resource?.content_link;
    const videoid = url.match(
      /(?:https?:\/{2})?(?:w{3}\.)?youtu(?:be)?\.(?:com|be)(?:\/watch\?v=|\/)([^\s&]+)/
    );

    const body = {
      organisation: organisationProfile && organisationProfile?.id,
      resource: resource?.id,
    };
    await api.resourceClick(body);

    if (videoid !== null) {
      setYoutubeId(videoid[1]);
    } else {
      window.open(url, '_blank', 'noreferrer');
    }
  };

  const handleChangeMobileFilters = ({
    contentType,
    selectedSkillList,
  }: ApplyFiltersProps) => {
    if (contentType) setContentType(contentType);
    if (selectedSkillList) setSelectedSkillList(selectedSkillList);

    setMobileFilters(false);
  };

  const handleFilterContentType = (selectedValue: any) => {
    if (contentType === selectedValue) {
      setContentType(undefined);
    } else {
      setContentType(selectedValue);
    }
  };

  if (!hasFinishedLoadingTaxonomy || loading) return <FullScreenLoading />;
  else {
    return (
      <>
        <div className="resources-container">
          <div className="resources-title">Resources</div>

          <div className="resources-body">
            <div className="resources-left-col">
              <div className="resources-label">Filter content type</div>

              <div className="resources-content-type-wrapper">
                <div
                  className="resources-content-type-container"
                  key={-1}
                  onClick={() => handleFilterContentType(undefined)}
                >
                  <div
                    className={`resouces-content-type-text ${
                      contentType === undefined &&
                      'resources-content-type-text-selected'
                    }`}
                  >
                    All
                  </div>
                  <RadioButton
                    selected={contentType === undefined}
                    bigger={true}
                  />
                </div>
                {CONTENT_TYPE.map((type, idx) => (
                  <div
                    className="resources-content-type-container"
                    key={idx}
                    onClick={() => setContentType(type.value)}
                  >
                    <div
                      className={`resouces-content-type-text ${
                        contentType === type.value &&
                        'resources-content-type-text-selected'
                      }`}
                    >
                      {type?.label}
                    </div>
                    <RadioButton
                      selected={contentType === type.value}
                      bigger={true}
                    />
                  </div>
                ))}
              </div>

              <div className="resources-label">Learning topics</div>
              <input
                value={searchTopics}
                onChange={handleSearchTaxonomy}
                placeholder="Search topics"
                className="resources-learning-input"
              />

              <div
                className="resources-selec-all-container"
                onClick={handleSelectAll}
              >
                <div
                  className={`resouces-select-all-text ${
                    selectAll && 'resources-select-all-text-selected'
                  }`}
                >
                  Select all
                </div>
                <RadioButton selected={selectAll} bigger={true} />
              </div>

              <ResourcesSearchFilters
                addRemoveSkillId={addRemoveSkillId}
                taxonomy={filteredTaxonomy}
                selectedSkillList={selectedSkillList}
                visibleIdList={visibleIdList}
                setVisibleIdList={setVisibleIdList}
              />
            </div>

            <div className="resources-right-col">
              <div className="resources-right-input-container">
                <div
                  className="resources-mobile-filters"
                  onClick={() => setMobileFilters(true)}
                >
                  <FilterAltIcon />
                </div>
                <div
                  className="resources-text-input"
                  onClick={() => document.getElementById('text-input')?.focus()}
                >
                  <SearchIcon />
                  <input
                    placeholder="Search keywords"
                    id="text-input"
                    value={textValue}
                    onChange={handleChangeTextValue}
                  />
                </div>
              </div>

              {resources.length === 0 && (
                <div className="resources-no-results">
                  <Grid
                    container
                    direction="column"
                    alignItems="center"
                    justifyContent="center"
                    style={{ height: '70%', marginTop: '30px' }}
                    className="no-mentor-selected__container"
                  >
                    <Stack direction="column" spacing={1}>
                      <CustomTypography
                        sx={{ fontSize: '25px', fontWeight: 'bold' }}
                      >
                        Sorry, we couldn't find any results
                      </CustomTypography>
                      <img
                        src="/no-experts.png"
                        alt="Sorry, we couldn't find any results"
                        height="300px"
                      />
                    </Stack>
                  </Grid>
                </div>
              )}

              <div className="resources-grid-container">
                {resources
                  ?.slice(0, counter)
                  ?.map((resource: any, idx: number) => (
                    <ResourceCard
                      key={idx}
                      image={resource?.image_link}
                      title={resource?.title}
                      type={resource?.content_link_type}
                      duration={resource?.duration_text}
                      clickCard={() => handleClickCard(resource)}
                    />
                  ))}
              </div>

              {resources.length > 6 && resources.length > counter && (
                <div className="resources-button-container">
                  <DashboardFilledButton
                    sx={{ width: '200px' }}
                    variant="contained"
                    onClick={() => setCounter(counter + 6)}
                  >
                    Load more
                  </DashboardFilledButton>
                </div>
              )}
            </div>
          </div>
        </div>

        <ResourcesMobileFilters
          visible={mobileFilters}
          onClose={() => setMobileFilters(false)}
          applyFilters={handleChangeMobileFilters}
          taxonomy={taxonomy}
          contentTypeData={CONTENT_TYPE}
        />

        <YoutubeModal
          visible={Boolean(youtubeId)}
          youtubeId={youtubeId}
          onClose={() => setYoutubeId(null)}
        />
      </>
    );
  }
};

export default Resources;
