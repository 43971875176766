import { styled } from '@mui/system';
import TextField from '@mui/material/TextField';
import { FormLabel, Select, Button } from '@mui/material';
import FormControlLabel from '@mui/material/FormControlLabel';
import { Typography } from '@mui/material';
import Badge from '@mui/material/Badge';
export const CustomTextField = styled(TextField)({
  fontSize: '16px',
  marginTop: '10px',
  marginRight: '10px',
  marginBottom: '10px',
  fontFamily: 'Gilroy',
  borderColor: '#333',
  '& .MuiOutlinedInput-root': {
    fontFamily: 'Gilroy',

    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: '#3333',
    },
    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
      borderColor: '#333',
    },
  },
});

export const CustomTextFieldActionPlanName = styled(TextField)({
  fontSize: '16px',
  marginRight: '10px',
  fontFamily: 'Gilroy',
  borderColor: '#333',
  '& .MuiInputLabel-root': {
    fontFamily: 'Gilroy',
  },
  '& .MuiInput-root': {
    fontFamily: 'Gilroy',
  },

  '& .MuiOutlinedInput-notchedOutline': {
    borderColor: '#3333',
    fontFamily: 'Gilroy',
  },
  '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
    borderColor: '#333',
    fontFamily: 'Gilroy',
  },
});
export const DatePopperButton = styled(Button)({
  width: '240px',
  height: '46px',
  margin: '4px 0 0',
  borderRadius: ' 8px',
  border: 'solid 1.2px #333',
  backgroundColor: '#fff',
  fontFamily: 'Gilroy',
  textTransform: 'none',
  fontSize: '14px',
  color: '#414141',
  textAlign: 'left',
});

export const AvailabilityActionPlanButton = styled(Button)({
  width: '100%',
  height: '50px',
  borderRadius: '10px',
  border: '1px solid #E8E8E8',
  backgroundColor: '#fff',
  fontFamily: 'Gilroy',
  textTransform: 'none',
  '&:hover': {
    backgroundColor: '#fff',
    border: '1px solid black',
  },
});

export const CustomFormLabel = styled(FormLabel)({
  fontSize: '14px',
  marginBottom: '5px',
  fontWeight: '500',
  lineHeight: '1.3',
  color: '#333',
  fontFamily: 'Gilroy',

  '&.Mui-focused': {
    color: '#333',
  },
});

export const StyledBadge = styled(Badge)(({ theme }) => ({
  '& .MuiBadge-badge': {
    right: -4,
    top: -5,
    height: '18px',
  },
}));

export const CustomTypography = styled(Typography)({
  fontSize: '16px',
  lineHeight: '1.3',
  color: '#414141',
  fontFamily: 'Gilroy',
  wordBreak: 'break-all',
});
export const CustomTypographyPermission = styled(Typography)({
  fontSize: '16px',
  lineHeight: '1.3',
  fontWeight:'500',
  fontFamily: 'Gilroy',
});
export const CustomSelectAvailabilityTypography = styled(Typography)({
  lineHeight: '10.74px',
  color: '#000000',
  fontFamily: 'Gilroy',
});

export const CustomTypographyPrimaryColor = styled(Typography)(({ theme }) => ({
  color: theme.palette.primary.main,
  fontFamily: 'Gilroy',
}));
export const CheckboxCustomTypography = styled(Typography)({
  fontSize: '14px',
  fontWeight: '500',
  lineHeight: '1.3',
  letterSpacing: '0.28px',
  textAlign: 'left',
  color: '#333',
  fontFamily: 'Gilroy',
  marginLeft: '-12px',
});

export const CustomHeaderTypography = styled(Typography)({
  fontSize: '30px',
  marginBottom: '30px',
  fontWeight: 'bold',
  lineHeight: '1.1',
  textAlign: 'left',
  color: '#0f0f22',
  fontFamily: 'Gilroy',
});

export const CustomFormControlLabel = styled(FormControlLabel)({
  margin: '0 0 0 0px',
});

export const blue = {
  50: '#F0F7FF',
  100: '#C2E0FF',
  200: '#80BFFF',
  300: '#66B2FF',
  400: '#3399FF',
  500: '#007FFF',
  600: '#0072E5',
  700: '#0059B2',
  800: '#004C99',
  900: '#003A75',
};

export const TimeSelect = styled(Select)({
  justifyContent: 'left',
  height: '50px',
  textTransform: 'none',
  width: ' 100%',
  borderRadius: '16px',
  fontFamily: 'Gilroy',
  fontSize: '13px',
  fontWeight: '500',
  borderColor: '#A3A3A3',
  lineHeight: '15px',
  color: '#181F39',
});

export const CustomSelectAvailabilityTextField = styled(TextField)({
  fontSize: '16px',
  fontWeight: '500',
  color: '#A3A3A3',
  width: '751px',
  height: '108px',
  marginRight: '10px',
  borderWidth: '1px',
  marginBottom: '10px',
  fontFamily: 'Gilroy',
  borderColor: '#A3A3A3',
  '& .MuiInputBase-root': {
    fontFamily: 'Gilroy',
  },
  '& .MuiOutlinedInput-root': {
    borderRadius: '15px',
  },
});

export const CardTextField = styled(TextField)({
  fontFamily: 'Gilroy',
  backgroundColor: 'white',
  borderRadius: '10px',
  border: '1px solid #E8E8E8',
  fontSize: '15px',
  color: '#A3A3A3',
  fontStyle: 'normal',
  fontWeight: '500',
  lineHeight: '120%',
  letterSpacing: ' 0.15px',
  '& .MuiOutlinedInput-root': {
    fontFamily: 'Gilroy',
    fontStyle: 'normal',
    fontWeight: '500',
    lineHeight: '120%',
    letterSpacing: ' 0.15px',
    fontSize: '15px',
    '& input': {
      fontFamily: 'Gilroy',
      height: '15px',
      color: ' #333',
      backgroundColor: 'white',
      borderRadius: '10px',
    },
    '& fieldset': {
      color: ' #A3A3A3',
      fontSize: '15px',
      fontFamily: 'Gilroy',
      border: 'none',
      fontStyle: 'normal',
      fontWeight: '500',
      lineHeight: '120%',
      letterSpacing: ' 0.15px',
      borderRadius: '10px',
    },
  },
  '&:hover': {
    border: '1px solid black',
  },
});

export const NoBorderTextField = styled(TextField)({
  fontFamily: 'Gilroy',
  marginLeft: '25px',
  fontSize: '18px',
  fontStyle: 'normal',
  fontWeight: '700',
  lineHeight: '120%',
  letterSpacing: '0.18px',
  width: 'auto',
  height: '40px',
  textAlign: 'center',
  border: 'none',
  '& .MuiInputBase-input.Mui-disabled': {
    border: 'none',
    WebkitTextFillColor: '#000000',
    fontSize: '18px',
   fontFamily: 'Gilroy',
    fontStyle: 'normal',
    fontWeight: '700',
  },
  '& .MuiInput-underline:before': {
    borderBottomColor: 'white',
  },
  '& .MuiInput-underline:after': {
    borderBottomColor: 'white',
  },
});

export const NoBorderTextFieldMobile = styled(TextField)({
  fontFamily: 'Gilroy',
  marginLeft: '25px',
  fontSize: '14px',
  fontStyle: 'normal',
  fontWeight: '700',
  lineHeight: '120%',
  letterSpacing: '0.18px',
  width: '100%',
  textAlign: 'center',
  border: 'none',
  '& .MuiInputBase-input.Mui-disabled': {
    border: 'none',
    WebkitTextFillColor: '#000000',
    fontSize: '14px',
    fontFamily: 'Gilroy',
    fontStyle: 'normal',
    fontWeight: '700',
  },
  '& .MuiInput-underline:before': {
    borderBottomColor: 'white',
  },
  '& .MuiInput-underline:after': {
    borderBottomColor: 'white',
  },
});
export const CardButton = styled(Button)({
  fontFamily: 'Gilroy',
  width: '190px',
  height: '15px',
  padding: '10px 20px',
  fontSize: '16px',
  color: 'black',
  backgroundColor: 'white',
  borderRadius: '10px',
  border: '1px solid #E8E8E8',
  '&:hover': {
    backgroundColor: '#FADCD9',
  },
  '&:active': {
    backgroundColor: '#F7C1C1',
  },
});
