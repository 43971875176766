import React from 'react';
import Dialog from '@mui/material/Dialog';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import DialogContent from '@mui/material/DialogContent';

import { DashboardEmptyButton, DashboardFilledButton } from 'styling/buttons';
import useWindowSize from 'hooks/useWindowSize';

interface DeletePlanPopupProps {
  open: boolean;
  onClose: () => void;
  handleDeleteSelectedPlan: (id: number) => any;
  deletePlan: any;
}

const DeletePlanPopup: React.FC<DeletePlanPopupProps> = ({
  open,
  onClose,
  handleDeleteSelectedPlan,
  deletePlan,
}) => {
  const handleClose = () => {
    onClose();
  };
  const { width } = useWindowSize();
  const isMobile = width < 900;
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      sx={{
        borderRadius: '10px',
        border: '1px solid #A3A3A3',
        boxShadow: '0px 1px 4px 0px rgba(0, 0, 0, 0.15)',
      }}
    >
      <IconButton
        aria-label="close"
        onClick={async () => {
          onClose();
        }}
        sx={{
          padding: '0px',
          marginRight: '36px',
          alignSelf: 'flex-end',
          marginTop: isMobile ?'20px':'36px',
        }}
      >
        <CloseIcon />
      </IconButton>
      <DialogContent
        sx={{
          maxWidth: '500px',
         
        }}
      >
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            flexDirection: 'column',
            alignItems: 'center',
            rowGap: isMobile ? '20px' : '52px',
          }}
        >
          <div
            className="popup-text"
            style={{ textAlign: 'center' }}
          >
            If you continue, all the tasks will be removed and nobody will have
            access to this plan anymore. Do you want to delete it?
          </div>
          <div>
            <DashboardFilledButton
              variant="contained"
              sx={{
                width: isMobile ? '80px' : '189px',
                height: isMobile ? '35px' : '48px',
              }}
              onClick={() => handleDeleteSelectedPlan(deletePlan)}
            >
              Yes
            </DashboardFilledButton>
            <DashboardEmptyButton
              variant="outlined"
              sx={{
                width: isMobile ? '80px' : '189px',
                height: isMobile ? '35px' : '48px',
                marginLeft: isMobile ? '10px' : '22px',
              }}
              onClick={onClose}
            >
              No
            </DashboardEmptyButton>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default DeletePlanPopup;
