import React, { useState } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './request-complete.scss';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Checkbox from '@mui/material/Checkbox';
import { useNavigate } from 'react-router-dom';
import useSelectProfileData from '../../hooks/useSelectProfileData';
import useGetFollowUpRequest from '../../apiHooks/followUpRequests/useGetFollowUpRequest';
import useGetInterests from '../../apiHooks/useGetInterests';
import { getUrlParam } from '../../utils/url';
import apiInstance from '../../api/config';
import { OrgProfile } from '../../types';
import { DashboardEmptyButton, DashboardFilledButton } from 'styling/buttons';

const CustomCheckbox = ({ onChange, checked }: any) => (
  <Checkbox
    onChange={onChange}
    checked={checked}
    color="primary"
    sx={{ padding: 0 }}
  />
);

const Option = ({ label, onClick, checked }: any) => (
  <Stack direction="row" alignItems="center" className="option-container">
    <CustomCheckbox onChange={onClick} checked={checked} />
    <p className="option-container__label">{label}</p>
  </Stack>
);

const RequestComplete = () => {
  const navigate = useNavigate();
  const followUpId = getUrlParam('follow_up') as string;
  const interestSkillId = getUrlParam('interest_skill') as string;
  const hasInterestSkillId = !!interestSkillId;
  const isFollowUp = !!followUpId;
  const { isLoadingFollowUpRequest } = useGetFollowUpRequest({
    followUpId: isFollowUp ? followUpId : null,
  });
  const { interests, isLoadingInterests } = useGetInterests({
    interestSkillId: hasInterestSkillId ? interestSkillId : null,
  });
  const { organisationProfile, fetchAllProfileData } = useSelectProfileData();

  const [orgInterests, setOrgInterests] = useState(
    organisationProfile?.interests
  );

  const handleOrgInterests = async (id: number) => {
    let interestsArray = [orgInterests].flat();
    if (!orgInterests?.includes(id)) {
      interestsArray.push(id);
    } else {
      const index = interestsArray.indexOf(id);
      interestsArray.splice(index, 1);
    }

    setOrgInterests(interestsArray as number[]);
    const params = { interests: interestsArray };
    try {
      await apiInstance.patch<OrgProfile>(
        `api/organisation/organisations/me/`,
        params
      );

      toast.success('Skills preference successfully updated.', {
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } catch (e: any) {
      console.error(e);
    }
  };

  const handleClickBook = async () => {
    const params = { interests: orgInterests };
    try {
      const res = await apiInstance.patch<OrgProfile>(
        `api/organisation/organisations/me/`,
        params
      );
      if (res.status === 200) {
        fetchAllProfileData();
        navigate('/dashboard/actions');
      }

      navigate('/mentor-select');
    } catch (e: any) {
      console.error(e);
    }
  };

  const handleClickDashboard = async () => {
    const params = { interests: orgInterests };
    try {
      const res = await apiInstance.patch<OrgProfile>(
        `api/organisation/organisations/me/`,
        params
      );
      if (res.status === 200) {
        fetchAllProfileData();
        navigate('/dashboard/actions');
      }
    } catch (e: any) {
      console.error(e);
    }
  };

  return isFollowUp && isLoadingFollowUpRequest ? (
    <div className="request-complete__loading-container">
      <img
        className="request-complete__loading-spinner"
        src="/loading.gif"
        alt="loading"
      />
    </div>
  ) : (
    <Grid
      container
      xs={12}
      justifyContent="center"
      className="request-complete"
    >
      <Grid item container xs={12} sm={12} md={6} lg={5}>
        <Stack direction="column" alignItems="center">
          <h1>We've received your request!</h1>
          <img height="350px" src="/ok-request.png" alt="congratulations" />

          <h3>Thanks for your request!</h3>
          <div className="request-complete-info">
            <div className="steps-request-complete">
              <div>
                {' '}
                 We’ve sent you an email to confirm that
                we’ve received your request and we’ll email you again when we
                have been able to match you with a mentor. Please check your
                inbox to make sure you’re receiving our emails.
              </div>
            </div>
            <div className="steps-request-complete">
              <div>
                {' '}
                If you don’t receive an email from
                digitalboost@digitalboost.org.uk in the next 30 minutes, please
                email  <a href="mailto:contact@digitalboost.org.uk"> contact@digitalboost.org.uk</a>  so that we can help you
                receive our essential email reminders.
              </div>
            </div>
          </div>
        </Stack>
      </Grid>
      {!isLoadingInterests && (
        <Grid container xs={12}>
          <Stack
            direction="column"
            alignItems={{ xs: 'flex-start', sm: 'flex-start', md: 'center' }}
            className="suggestions-container"
            flex={1}
          >
            <h6 className="text-options">
              In the meantime, please tell us what other type of help you need.
              <br />
              We’ll save your preferences and recommend mentors and resources in
              the future.
            </h6>
            <Grid
              container
              spacing={1.5}
              marginBottom={1.5}
              alignItems="center"
              sx={{
                flexDirection: { xs: 'column', sm: 'column', md: 'row' },
                justifyContent: 'center',
              }}
            >
              {interests?.map((item) => (
                <Grid item>
                  <Option
                    checked={orgInterests?.includes(item?.interest?.id)}
                    label={item?.interest?.name}
                    onClick={() => handleOrgInterests(item?.interest?.id)}
                  />
                </Grid>
              ))}
            </Grid>
            <h6>Or</h6>
            <Stack
              direction={{ xs: 'column', sm: 'column', md: 'row' }}
              spacing={2}
              sx={{ width: { xs: '100%', sm: '100%', md: 'auto' } }}
            >
              <DashboardFilledButton
                variant="contained"
                onClick={handleClickBook}
                sx={{ width: '250px', height: '50px' }}
              >
                Request another session
              </DashboardFilledButton>
              <DashboardEmptyButton
                variant="outlined"
                sx={{
                  width: '200px',
                  height: '50px',
                }}
                onClick={handleClickDashboard}
              >
                View Dashboard
              </DashboardEmptyButton>
            </Stack>
          </Stack>
        </Grid>
      )}
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </Grid>
  );
};

export default RequestComplete;
