import { useCallback, useEffect } from 'react';
import useRedux from '../../hooks/useRedux';
import {
  // actions
  messageUsersAction,
  searchMessagesAction,
  sendMessageAction,
  blockUserAction,
  availableUsersAction,
  readMessagesAction,
  getUnreadMessagesCountAction,
  // selectors
  selectMessages,
  selectHasNextMessages,
  selectMessageUsers,
  selectHasNextUsers,
  selectAvailableUsers,
  selectHasNextAvailableUsers,
  loadingMessageUsers,
  loadingAvailableUsers,
} from './index';

interface Props {
  hasVolunteerIdUrl?: boolean;
}

const useMessagingCentre = ({ hasVolunteerIdUrl }: Props) => {
  /* --- HOOKS --- */
  const { useSelector, dispatch } = useRedux();

  /* --- ACTIONS --- */
  // messageUsers
  const getMessageUsers = useCallback(
    (name?: string, limit?: number) =>
      dispatch(messageUsersAction({ name, limit })),
    [dispatch]
  );
  // availableUsers
  const getAvailableUsers = useCallback(
    (name?: string, limit?: number) =>
      dispatch(availableUsersAction({ name, limit })),
    [dispatch]
  );

  // getBlockedUsers
  const blockUser = async (volunteerId: number, isBlocked: boolean) => {
    await dispatch(blockUserAction({ volunteerId, isBlocked }));
  };

  // searchMessages
  const searchMessages = async (volunteerId: number, limit?: number) => {
    await dispatch(searchMessagesAction({ volunteerId, limit }));
  };

  // sendMessage
  const sendMessage = async (volunteerId: number, text: string) => {
    await dispatch(sendMessageAction({ text, volunteerId }));
  };

  // readMessages
  const readMessages = async (volunteerId: number) => {
    await dispatch(readMessagesAction({ volunteerId }));
    await dispatch(getUnreadMessagesCountAction());
  };

  /* --- SELECTORS --- */
  // messageUsers
  const messageUsers = useSelector(selectMessageUsers);
  const isLoadingMessageUsers = useSelector(loadingMessageUsers);
  const hasNextUsers = useSelector(selectHasNextUsers);

  const availableUsers = useSelector(selectAvailableUsers);
  const isLoadingAvailableUsers = useSelector(loadingAvailableUsers);
  const hasNextAvailableUsers = useSelector(selectHasNextAvailableUsers);

  // fetchMessages
  const messages = useSelector(selectMessages);
  const hasNextMessages = useSelector(selectHasNextMessages);

  /* --- AUTO-FETCH --- */
  useEffect(() => {
    (async () => {
      await getMessageUsers(undefined, hasVolunteerIdUrl ? 100 : undefined);
      await getAvailableUsers();
    })();
  }, [getMessageUsers, getAvailableUsers, hasVolunteerIdUrl]);

  return {
    messageUsers,
    hasNextUsers,
    isLoadingMessageUsers,
    availableUsers,
    hasNextAvailableUsers,
    isLoadingAvailableUsers,
    searchMessages,
    messages,
    hasNextMessages,
    sendMessage,
    blockUser,
    getMessageUsers,
    getAvailableUsers,
    readMessages,
  };
};

export default useMessagingCentre;
