import React, { useState, useMemo, useEffect, useCallback } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import TabsUnstyled from '@mui/base/TabsUnstyled';

import DashboardInfo from './dashboardInfo';
import FullScreenLoading from 'components/FullScreenLoading';

import useGetBoostCallEventsScheduled from 'apiHooks/boostCalls/useGetBoostCallEventsScheduled';
import useSelectProfileData from 'hooks/useSelectProfileData';
import useGetGroupEvents from 'apiHooks/groupEvents/useGetGroupEvents';
import { useSystemTogglesContext } from 'context/SystemToggles';
import { BoostCallEventWithVolunteerProfile, GroupEvent } from 'types';

import { Tab, TabsList } from '../../styling/tabs';
import './dashboard.scss';

import useGetGroupEventsCompletedAndRegistered from 'apiHooks/groupEvents/useGetGroupEventsCompletedAndRegistered';
import useGetRecommendedMentors from 'apiHooks/recommendedMentors/useGetRecommendedMentors';
import useGetTotalSessions from 'apiHooks/stats/useGetTotalSessions';
import useGetBoostCallEventsTopInterests from 'apiHooks/boostCalls/useGetBoostCallEventsTopInterests';
import { bookingsBadge, displayIcon, tabs } from './utilities';
import useGetBoostCallEventReviews from 'apiHooks/boostCalls/useGetBoostCallEventReviews';
import useWindowSize from 'hooks/useWindowSize';
import useAuth from 'features/auth/hooks/useAuth';
import useGetBoostCallEventsPending from 'apiHooks/boostCalls/useGetBoostCallEventsPending';
import useGetFollowUpRequests from 'apiHooks/followUpRequests/useGetFollowUpRequests';
import { getAccessTokenCookie } from 'utils/auth';

interface Props {
  tab: string;
  subTab?: string;
}

const NewDashboard = ({ tab, subTab }: Props) => {
  const loggedInUser = !!getAccessTokenCookie();
  const [isNotDesktop, setisNotDesktop] = useState(false);
  const { width = window.innerWidth } = useWindowSize();
  const [hideGroupEvents, setHideGroupEvents] = useState<boolean | undefined>(
    undefined
  );
  const { logout } = useAuth();
  const {
    userLocation,
    organisationProfile,
    partner,
    hasFinishedLoadingOrganisationProfile,
    hasFinishedLoadingPartner,
  } = useSelectProfileData();

  let partnerId = null;

  if (partner) {
    partnerId = partner.id;
  }
  useEffect(() => {
    if (
      hasFinishedLoadingPartner &&
      partner &&
      partner.hide_group_events === true
    ) {
      setHideGroupEvents(true);
    } else setHideGroupEvents(false);
  }, [hasFinishedLoadingPartner, partner]);

  if (
    hasFinishedLoadingOrganisationProfile &&
    organisationProfile?.partnerInfo?.id
  ) {
    partnerId = organisationProfile?.partnerInfo?.id;
  }

  const [displayTabs, setDisplayTabs] = useState(tabs);
  useEffect(() => {
    let updatedTabs = tabs;
    if (
      hasFinishedLoadingOrganisationProfile &&
      organisationProfile?.partnerInfo?.hide_resources === true
    ) {
      updatedTabs = updatedTabs.filter((tab) => tab.label !== 'Resources');
    }
    if (
      hasFinishedLoadingOrganisationProfile &&
      organisationProfile?.partnerInfo?.hide_group_events === true
    ) {
      updatedTabs = updatedTabs.filter((tab) => tab.label !== 'Events');
    }
    setDisplayTabs(updatedTabs);
  }, [organisationProfile, hasFinishedLoadingOrganisationProfile]);

  const { toggles } = useSystemTogglesContext();
  const showActionPlan = toggles['ACTION_PLAN'];
  useEffect(() => {
    if (!showActionPlan) {
      const updatedTabs = tabs.filter((tab) => tab.label !== 'My Action Plans');
      setDisplayTabs(updatedTabs);
    } else setDisplayTabs(tabs);
  }, [showActionPlan]);
  useEffect(() => {
    const handleResize = () => {
      if (width < 1200) {
        setisNotDesktop(true);
      } else {
        setisNotDesktop(false);
      }
    };
    handleResize();
  }, [width]);

  const {
    boostCallEventsScheduled,
    hasFinishedLoadingBoostCallEventsScheduled,
    refetchBoostCallEventsScheduled,
  } = useGetBoostCallEventsScheduled();
  const {
    boostCallEventsPending,
    hasFinishedLoadingBoostCallEventsPending,
    refetchBoostCallEventsPending,
  } = useGetBoostCallEventsPending();

  const {
    groupEventResultsData: myGroupEvents,
    hasFinishedLoadingGroupEvents: hasFinishedLoadingMyGroupEvents,
    refetchGroupEvent,
  } = useGetGroupEvents({
    partnerId: partnerId,
    userStatus: 'registered',
  });
  const myGroupEventsRegistered = myGroupEvents?.filter(
    (entry: any) =>
      entry.organisation_registered !== false ||
      entry.organisation_invited !== false
  );

  const {
    groupEventResultsData: invitedGroupEvents,
    hasFinishedLoadingGroupEvents: hasFinishedLoadingInvitedGroupEvents,
  } = useGetGroupEvents({ userStatus: 'invited' });

  const {
    boostCallGroupEventsCompleted,
    hasFinishedLoadingBoostCallGroupEventsCompleted,
    refetchBoostCallGroupEventsCompleted,
  } = useGetGroupEventsCompletedAndRegistered();

  const {
    pendingFollowUpRequests,
    hasFinishedLoadingFollowUpRequest,
    refetchFollowUpRequest,
  } = useGetFollowUpRequests(); // FollowUpRequests

  // recommendations
  const {
    groupEventResultsData: groupEvents,
    hasFinishedLoadingGroupEvents,
    refetchGroupEvent: refetchRecommendedGroupEvents,
  } = useGetGroupEvents({
    partnerId: partnerId,
    eventStatus: 'scheduled',
    userStatus: 'other',
    interests: toggles['DISPLAY_ALL_GROUP_EVENTS_ON_DASHBOARD']
      ? undefined
      : organisationProfile?.interests,
  });
  // recommendations invited
  const {
    groupEventResultsData: groupEventsInvited,
    hasFinishedLoadingGroupEvents: hasFinishedLoadingGroupEventsInvited,
    refetchGroupEvent: refetchRecommendedGroupEventsInvited,
  } = useGetGroupEvents({
    partnerId: partnerId,
    eventStatus: 'scheduled',
    userStatus: 'invited',
    interests: toggles['DISPLAY_ALL_GROUP_EVENTS_ON_DASHBOARD']
      ? undefined
      : organisationProfile?.interests,
  });
  const {
    recommendedResultsData: recommendedData,
    refetchRecommendedData,
    hasFinishedLoadingRecommendedMentors,
  } = useGetRecommendedMentors();

  const refetchEvents = useCallback(() => {
    refetchBoostCallEventsScheduled();
    refetchBoostCallEventsPending();
    refetchBoostCallGroupEventsCompleted();
    refetchRecommendedData();
    refetchGroupEvent();
    refetchFollowUpRequest();
    refetchRecommendedGroupEvents();
    refetchRecommendedGroupEventsInvited();
  }, [
    refetchBoostCallEventsScheduled,
    refetchBoostCallEventsPending,
    refetchBoostCallGroupEventsCompleted,
    refetchRecommendedData,
    refetchGroupEvent,
    refetchFollowUpRequest,
    refetchRecommendedGroupEvents,
    refetchRecommendedGroupEventsInvited,
  ]);

  // metrics
  let { totalSessions } = useGetTotalSessions();
  if (totalSessions === undefined) totalSessions = [];
  const { reviews, hasFinishedLoadingReviews } = useGetBoostCallEventReviews();

  const getTotalGroupEventsCompleted = useMemo(() => {
    return boostCallGroupEventsCompleted.filter(
      (recordedEvent) => recordedEvent.call_url !== null
    );
  }, [boostCallGroupEventsCompleted]);
  const { topInterests, hasFinishedLoadingBoostCallEventsTopInterests } =
    useGetBoostCallEventsTopInterests();

  const groupEventsShow = toggles['SHOW_GROUP_EVENTS']
    ? myGroupEventsRegistered
    : [];

  const upcomingGroupEvents = groupEventsShow
    .filter((event) => event.status === 'scheduled')
    .sort(
      (a: GroupEvent, b: GroupEvent) =>
        new Date(a.start_time).getTime() - new Date(b.start_time).getTime()
    );

  const upcomingCallsAndGroupEvents = [
    ...upcomingGroupEvents,
    ...boostCallEventsScheduled,
  ].sort(
    (
      a: BoostCallEventWithVolunteerProfile | GroupEvent,
      b: BoostCallEventWithVolunteerProfile | GroupEvent
    ) => new Date(a.start_time).getTime() - new Date(b.start_time).getTime()
  );

  const upcomingCalls = [...boostCallEventsScheduled].sort(
    (
      a: BoostCallEventWithVolunteerProfile,
      b: BoostCallEventWithVolunteerProfile
    ) => new Date(a.start_time).getTime() - new Date(b.start_time).getTime()
  );

  const isLoading =
    !hasFinishedLoadingBoostCallEventsPending ||
    !hasFinishedLoadingMyGroupEvents ||
    !hasFinishedLoadingBoostCallEventsScheduled ||
    !hasFinishedLoadingInvitedGroupEvents ||
    !hasFinishedLoadingBoostCallGroupEventsCompleted ||
    !hasFinishedLoadingGroupEvents ||
    !hasFinishedLoadingRecommendedMentors ||
    !hasFinishedLoadingBoostCallEventsTopInterests ||
    !hasFinishedLoadingReviews ||
    !hasFinishedLoadingFollowUpRequest ||
    !hasFinishedLoadingGroupEventsInvited;

  interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: string;
  }
  let history = useNavigate();
  let display = 0;

  function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;
    if (value === 'security') display = 1;
    else if (value === 'privacy') display = 2;
    else if (value === 'profile') display = 0;
    return (
      <div
        role="tabpanel"
        hidden={display !== index}
        id={`vertical-tabpanel-${index}`}
        aria-labelledby={`vertical-tab-${index}`}
        {...other}
      ></div>
    );
  }
  const [loadedOnce, setLoadedOnce] = useState(false);
  const data = {
    upcomingGroupEvents: upcomingGroupEvents,
    boostCallGroupEventsCompleted: boostCallGroupEventsCompleted,
    upcomingCalls: upcomingCalls,
    upcomingCallsAndGroupEvents: upcomingCallsAndGroupEvents,
    upcomingRequests: boostCallEventsPending,
    groupEventRequestData: invitedGroupEvents,
    recommendedEvents: groupEvents,
    recommendedEventsInvited: groupEventsInvited,
    recommendedMentors: recommendedData,
    timezone: userLocation && userLocation.timezone,
    totalSessions: totalSessions,
    masterclasses: getTotalGroupEventsCompleted.length,
    topInterests: topInterests,
    reviews: reviews,
    hideGroupEvents: hideGroupEvents,
    followupRequests: pendingFollowUpRequests,
    refetchEvents: refetchEvents,
    refetchRecommendedData: refetchRecommendedData,
  };
  const handleChange = (event: React.SyntheticEvent, newValue: any) => {
    if (newValue === 'logout') logout();
    else {
      history(`/dashboard/${newValue}`);
    }
  };
  function a11yProps(index: number) {
    return {
      id: `vertical-tab-${index}`,
      'aria-controls': `vertical-tabpanel-${index}`,
    };
  }
  const location = useLocation();
  useEffect(() => {
    // refetch all event data whenever a user visits the dashboard
    if (location.pathname.includes('/dashboard/actions')) {
      refetchEvents();
      setLoadedOnce(true);
    }
  }, [location.pathname, refetchEvents]);

  return !isLoading || loadedOnce ? (
    <>
      {isNotDesktop ? (
        <div className="dashboard-container-info">
          <DashboardInfo tab={tab} data={data} />
        </div>
      ) : (
        <div className="container__dashboard-page">
        <div>
            <TabsUnstyled value={tab} onChange={handleChange}>
            {loggedInUser?   <TabsList>
                {displayTabs.map((tab) => (
                  <Tab
                    key={tab.link}
                    value={tab.link}
                    sx={{ fontFamily: 'Gilroy', fontSize: '16px' }}
                    {...a11yProps(0)}
                  >
                    <div className="dashboard-tab-icon-label" key={tab.link}>
                      {displayIcon(tab.label)} {tab.label}
                      {tab.label === 'Mentoring'
                        ? bookingsBadge(upcomingCallsAndGroupEvents)
                        : null}{' '}
                      {tab.label === 'Requests'
                        ? bookingsBadge(boostCallEventsPending)
                        : null}{' '}
                    </div>
                  </Tab>
                ))}
              </TabsList> :null}
              <TabPanel value={tab} index={0} key={0} />
              <TabPanel value={tab} index={1} key={1} />
              <TabPanel value={tab} index={2} key={2} />
              <TabPanel value={tab} index={3} key={3} />
              <TabPanel value={tab} index={4} key={4} />
              <TabPanel value={tab} index={5} key={5} />
              <TabPanel value={tab} index={6} key={6} />
            </TabsUnstyled>
          </div>
          <div className="dashboard-container-info">
            <DashboardInfo tab={tab} data={data} subTab={subTab} />
          </div>
        </div>
      )}
    </>
  ) : (
    <FullScreenLoading />
  );
};

export default NewDashboard;
