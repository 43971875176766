import React, { useState, useEffect } from 'react';
import './styling/actions.scss';
import './styling/requests.scss';
import { LoadMoreButton } from '../styling';
import { BoostCallEvent, GroupEvent } from '../../../types';
import NewUpcomingCard from '../NewUpcomingCard';
import NewInviteCard from '../NewInviteCard';
import NewRescheduleCard from '../NewRescheduleCard';
import NewRequestedCard from '../NewRequestedCard';
import RecommendationsSection from '../RecommendationsSection';
import IconButton from '@mui/material/IconButton';
import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined';
import Menu from '@mui/material/Menu';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Checkbox from '@mui/material/Checkbox';
import { CheckboxCustomTypography } from 'styling/generalStyling';
import { Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import RescheduleDetails from '../RescheduleDetails';
import NewFollowupCard from '../NewFollowupCard';
import UpcomingDetails from '../UpcomingDetails';
import FollowupDetails from '../FollowupDetails';
import { getUrlParam } from 'utils/url';
import DashboardEmptyPage from './dashboardEmptyPage';
import LatestNews from './latestNews';
import useSelectProfileData from 'hooks/useSelectProfileData';
import useFetchPartner from 'apiHooks/partner/useFetchPartner';
import useGetNews from 'apiHooks/useGetNews';
import Slider from 'react-slick';
interface Props {
  upcoming: Array<any>;
  timeZoneString?: string;
  requested: Array<any>;
  hideGroupEvents:boolean;
  events: Array<any>;
  followup: Array<any>;
  mentors: Array<any>;
  groupEventRequests?: GroupEvent[];
  refetchEvents: any;
  refetchRecommendedData: any;
}

const Actions = ({
  upcoming,
  requested,
  events,
  hideGroupEvents,
  followup,
  mentors,
  groupEventRequests,
  timeZoneString = '',
  refetchEvents,
  refetchRecommendedData,
}: Props) => {
  const navigate = useNavigate();
  var totalEvents = [
    ...upcoming,
    ...requested,
    ...followup,
    ...(groupEventRequests ? groupEventRequests : []),
  ].length;

  const code = getUrlParam('code') as string;
  const { news } = useGetNews();

  const [rescheduleRequest, setRescheduleRequest] = useState<BoostCallEvent>();
  const [details, setDetails] = useState(null);
  const [filteredList, setFilteredList] = useState<string[]>([]);
  const [followupDetails, setFollowupDetails] = useState(null);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [rescheduleDetails, setRescheduleDetails] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const [checked, setChecked] = React.useState<number[]>([]);
  const { organisationProfile } =
    useSelectProfileData();
  const { partner: fetchPartnerResponseData, isLoadingPartner } =
    useFetchPartner(organisationProfile?.partner || null);
  const handleToggle = (value: number) => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setChecked(newChecked);
  };
  useEffect(() => {
    const rescheduleRequestInUpcoming =
      upcoming &&
      upcoming.find(
        (u): u is BoostCallEvent =>
          u.reschedule_status &&
          u.reschedule_status === 'pending' &&
          u.reschedule_requested_by === 'organisation'
      );
    if (rescheduleRequestInUpcoming)
      setRescheduleRequest(rescheduleRequestInUpcoming);
  }, [upcoming]);

  const [counter, setCounter] = useState(4);

  requested.map((event, index) => (event['type'] = 'request'));

  const [displayedEvents, setDisplayedEvents] = useState<any[]>([]);
  useEffect(() => {
    const applyFilter = (events: any[]) => {
      const check1to1Request = (event: any) => {
        return event.status === 'pending' && event.type === 'request';
      };
      const check1to1Upcoming = (event: any) => {
        return (
          event.status === 'scheduled' &&
          event.title === '1-to-1 mentoring call'
        );
      };
      const checkRescheduleRequests = (event: any) => {
        return (
          event.status === 'scheduled' && event.reschedule_status === 'pending'
        );
      };
      const checkMasterclassInvites = (event: any) => {
        return event.type === 'masterclass' && event.status === 'invited';
      };
      const checkMasterclassUpcoming = (event: any) => {
        return event.type === 'masterclass' && event.status === 'scheduled';
      };
      const checkFollowup = (event: any) => {
        return event.is_follow_up_wanted;
      };
      var todisplayEvents: any[] = [];
      if (checked.includes(0) === true)
        todisplayEvents = [
          ...todisplayEvents,
          ...events.filter(check1to1Request),
        ];
      if (checked.includes(1) === true)
        todisplayEvents = [
          ...todisplayEvents,
          ...events.filter(check1to1Upcoming),
        ];
      if (checked.includes(2) === true)
        todisplayEvents = [
          ...todisplayEvents,
          ...events.filter(checkRescheduleRequests),
        ];
      if (checked.includes(3) === true)
        todisplayEvents = [
          ...todisplayEvents,
          ...events.filter(checkMasterclassInvites),
        ];
      if (checked.includes(4) === true)
        todisplayEvents = [
          ...todisplayEvents,
          ...events.filter(checkMasterclassUpcoming),
        ];
      if (checked.includes(5) === true)
        todisplayEvents = [...todisplayEvents, ...events.filter(checkFollowup)];

      //remove duplicates
      const noDuplicateDisplayEvents = todisplayEvents.filter(
        (value, index, self) =>
          index === self.findIndex((t) => t.id === value.id)
      );

      setDisplayedEvents(noDuplicateDisplayEvents);
    };
    const filterRequests = (requests: any[]) => {
      const proposedTimeSlot = (event: { time_slots: any[] }) =>
        event.time_slots.find(
          (ts: { status: string }) => ts.status === 'time_slot_proposed'
        );

      let finalRequests = requests.filter(
        (request: { time_slots: any[] }) =>
          proposedTimeSlot(request) !== undefined
      );
      return finalRequests;
    };
    var eventsArray = [
      ...upcoming,
      ...filterRequests(requested),
      ...followup,
      ...(groupEventRequests ? groupEventRequests : []),
    ];

    //remove duplicates
    const eventsNoDuplicate = eventsArray.filter(
      (value, index, self) => index === self.findIndex((t) => t.id === value.id)
    );

    //remove events where volunteers deleted account
    const finalEvents = eventsNoDuplicate.filter(
      (event) => event.volunteer !== null
    );

    if (rescheduleRequest) eventsArray.push(rescheduleRequest);
    if (checked.length > 0) applyFilter(finalEvents);
    else setDisplayedEvents(finalEvents);
  }, [
    checked,
    followup,
    events,
    groupEventRequests,
    requested,
    rescheduleRequest,
    upcoming,
  ]);
  useEffect(() => {
    const list = hideGroupEvents
      ? ['Requests', 'Upcoming sessions', 'Reschedule requests', 'Follow-up']
      : [
          'Requests',
          'Upcoming sessions',
          'Reschedule requests',
          'Masterclass invites',
          'Upcoming Masterclasses',
          'Follow-up',
        ];
    setFilteredList(list);
  }, [hideGroupEvents]); 

  const addArraytoArray = (arr1: any[], arr2: any[]) => {
    let i = 0;
    while (i < arr2.length) {
      arr1.push(arr2[i]);
      i++;
    }
    return arr1;
  };
  
  const sortByPriority = (events: any[]) => {
    const today = new Date();
    const tomorrow = new Date(today);
    tomorrow.setDate(tomorrow.getDate() + 1);
    var topPriorityRequests = events.filter(
      (event) =>
        event.status === 'scheduled' && new Date(event.start_time) <= tomorrow
    );
    var secondPriorityRequests = events.filter(
      (event) =>
        event.status === 'pending' || event.reschedule_status === 'pending'
    );
    var thirdPriority = events.filter(
      (event) =>
        event.status === 'scheduled' &&
        event.reschedule_status !== 'pending' &&
        new Date(event.start_time) > tomorrow
    );
    const top3Requests = [
      ...topPriorityRequests,
      ...secondPriorityRequests,
      ...thirdPriority,
    ];
    const remainingEvents = events.filter(
      (ev) => !top3Requests.some((ev2) => ev2.id === ev.id)
    );

    var finalEventsArray: any[] = [];
    addArraytoArray(finalEventsArray, topPriorityRequests);
    addArraytoArray(finalEventsArray, secondPriorityRequests);
    addArraytoArray(finalEventsArray, thirdPriority);
    addArraytoArray(finalEventsArray, remainingEvents);

    //remove duplicates
    finalEventsArray = finalEventsArray.filter(
      (value, index, self) => index === self.findIndex((t) => t.id === value.id)
    );
    return finalEventsArray;
  };

  const numberOfFilters = () => {
    if (checked.length === 0)
      return <div className="filters-dashboard">Filters</div>;
    else if (checked.length === 5)
      return <div className="filters-dashboard">All</div>;
    else if (checked.length === 1)
      return <div className="filters-dashboard">1 filter</div>;
    else
      return <div className="filters-dashboard">{checked.length} filters</div>;
  };

  const displayEvents = () => {
    return sortByPriority(displayedEvents)
      .slice(0, counter)
      .map((event, index) => {
        if (event.reschedule_status === 'pending')
          return (
            <NewRescheduleCard
              key={index}
              timeZone={timeZoneString}
              appointmentData={event}
              subject="Upcoming"
              clickManage={(e) => setDetails(e)}
              refetchEvents={refetchEvents}
            />
          );
        else if (
          (event.type &&
            event.organisation_registered &&
            event.status === 'scheduled') ||
          (!event.type && event.status === 'scheduled')
        )
          return (
            <NewUpcomingCard
              key={index}
              timeZone={timeZoneString}
              appointmentData={event}
              subject="Upcoming"
              refetchEvents={refetchEvents}
              clickManage={(e) => setDetails(e)}
            />
          );
        if (event.type === 'request') {
          return (
            <NewRequestedCard
              key={index}
              timeZoneString={timeZoneString}
              appointment={event}
            />
          );
        } else if (event.is_follow_up_wanted) {
          return (
            <NewFollowupCard
              followup={event}
              clickViewDetails={(e) => setFollowupDetails(e)}
            />
          );
        } else {
          return <NewInviteCard key={index} inviteData={event} />;
        }
      });
  };

  useEffect(() => {
    if (code) {
      const proposeNewTimeEvent = displayedEvents?.filter((evt: any) => {
        return evt?.time_slots?.filter((slot: any) => slot.code === code);
      });
      if (
        proposeNewTimeEvent?.length > 0 &&
        proposeNewTimeEvent?.[0]?.status === 'pending'
      ) {
        setRescheduleDetails(proposeNewTimeEvent?.[0]);
      }
    }
  }, [displayedEvents, code]);

  const settings = {
    dots: false,
    arrows: false,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    adaptiveHeight: true,
    autoplay: true,
    autoplaySpeed: 5000,
  };

  if (rescheduleDetails) {
    return (
      <RescheduleDetails
        details={rescheduleDetails}
        slotId={undefined}
        timeZone={timeZoneString}
      />
    );
  } else if (details) {
    return (
      <UpcomingDetails
        details={details}
        timeZone={timeZoneString}
        refetchEvents={refetchEvents}
      />
    );
  } else if (followupDetails) {
    return (
      <FollowupDetails
        details={followupDetails}
        timeZone={timeZoneString}
        setFollowUpDetails={setFollowupDetails}
      />
    );
  } else {
    return (
      <div>
        <div className="dashboard-title-component">
          <div className="dashboard-title-text">
            Welcome {organisationProfile?.first_name}!
            <div>Here's what you have coming up</div>
          </div>
          <div>
            {totalEvents > 0 ? (
              <IconButton
                sx={{ color: '#333', padding: 0 }}
                aria-controls={open ? 'basic-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
                onClick={handleClick}
              >
                <FilterAltOutlinedIcon sx={{ color: '#333' }} />
                {numberOfFilters()}
              </IconButton>
            ) : null}
            <Menu
              id="basic-menu"
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
              MenuListProps={{
                'aria-labelledby': 'basic-button',
              }}
            >
              <div className="filter-menu-title">Filter your actions</div>
              <List sx={{ padding: 0 }}>
                {filteredList.map((label, value) => {
                  const labelId = `checkbox-list-label-${value}`;

                  return (
                    <ListItem
                      sx={{ marginBottom: '-15px', padding: 0 }}
                      key={value}
                      disablePadding
                    >
                      <ListItemButton
                        sx={{ paddingLeft: 0 }}
                        role={undefined}
                        onClick={handleToggle(value)}
                        dense
                      >
                        <ListItemIcon>
                          <Checkbox
                            color="primary"
                            checked={checked.indexOf(value) !== -1}
                            tabIndex={-1}
                            disableRipple
                            inputProps={{ 'aria-labelledby': labelId }}
                          />
                        </ListItemIcon>
                        <ListItemText
                          id={labelId}
                          primary={
                            <CheckboxCustomTypography>
                              {label}
                            </CheckboxCustomTypography>
                          }
                        />
                      </ListItemButton>
                    </ListItem>
                  );
                })}
              </List>
            </Menu>
          </div>
        </div>
        <div className="new-dashboard__wrapper">
          <div className="dashboard-actions-container">
            {totalEvents > 0 ? (
              <div>
                {displayedEvents.length ? (
                  <div className="dashboard-cards">
                    {displayEvents()}
                    {displayedEvents.length > 4 &&
                    counter < displayedEvents.length ? (
                      <div className="loading-button-container">
                        <LoadMoreButton
                          variant="contained"
                          onClick={() => setCounter(counter + 4)}
                        >
                          Load more
                        </LoadMoreButton>
                      </div>
                    ) : null}
                  </div>
                ) : (
                  <div className="filter-error-text">
                    Nothing found. Please try changing the filter to view
                    results.
                  </div>
                )}
              </div>
            ) : (
              <DashboardEmptyPage />
            )}
          </div>

          <div className="new-dashboard__impact">
            {!fetchPartnerResponseData?.hide_news &&
            !isLoadingPartner &&
            news?.length > 0 ? (
              <div className="dashboard-news-section">
                <div className="dashboard-impact-title">News</div>
                <Slider {...settings} className="news-carousel">
                  {news.map((item: any) => (
                    <LatestNews id="news-item" text={item.content} />
                  ))}
                </Slider>
              </div>
            ) : null}
            <div className="dashboard-impact-title-container">
              <div className="dashboard-impact-title">Recommendations</div>
              <Button
                sx={{
                  textTransform: 'none',
                  width: '100px',
                  height: '20px',
                  color: '#333',
                }}
                onClick={() => navigate('/dashboard/recommendations')}
              >
                View all
              </Button>
            </div>
            <RecommendationsSection
              events={events}
              hideGroupEvents={hideGroupEvents}
              mentors={mentors?.filter(
                (mentor: any) => !mentor?.is_access_paused
              )}
              timeZone={timeZoneString}
              refetchRecommendedData={refetchRecommendedData}
            />
          </div>
        </div>
      </div>
    );
  }
};

export default Actions;
