import React from 'react';
import { BarChart, Bar, XAxis, Tooltip } from 'recharts';
import useWindowSize from '../../../hooks/useWindowSize';

interface Props {
  data: any[];
  statsNumber: number;
  statsThisMonth: number;
  chartText: string;
}

export default function ChartDumpComponent({
  data,
  statsNumber,
  statsThisMonth,
  chartText,
}: Props) {
  const { width: screenWidth = null } = useWindowSize();
  const isMobile = screenWidth && screenWidth < 950;
  return (
    <div>
      <div className="tootltip-statistics">
        <div className="tootltip-statistics-icon">
          <div className="sessions-photo-card-container">
            <img
              className="octagon-sessions-photo"
              src={'/user-octagon.png'}
              alt={'user-octagon'}
            />
          </div>
          <div className="tootltip-statistics-icon-info">
            <div>{statsNumber}</div>
            <div className="chart-text-container">
              <div className="chart-bold-text">Number of {chartText}</div>
              <div className="chart-text">
                This month: {statsThisMonth ? statsThisMonth : 0}
              </div>
            </div>
          </div>
        </div>
      </div>

      <BarChart
        width={isMobile ? 300 : 459}
        height={250}
        data={data}
        margin={{
          top: isMobile ? 100 : 25,
          right: 30,
          left: 20,
          bottom: 5,
        }}
      >
        <XAxis dataKey="name" />

        <Tooltip />
        <Bar dataKey="sessions" fill="#b2d5ef" />
      </BarChart>
    </div>
  );
}
