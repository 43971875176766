import {
  Box,
  Checkbox,
  FormControlLabel,
  Modal,
  Radio,
  RadioGroup,
} from '@mui/material';
import { shareActionPlan } from 'api/actionPlan';
import useWindowSize from 'hooks/useWindowSize';
import React, { useEffect, useState } from 'react';
import { DashboardFilledButton, DashboardEmptyButton } from 'styling/buttons';
import { CustomTypographyPermission } from 'styling/generalStyling';

import './style.scss';

interface UserSelectCardProps {
  id: string;
  profilePicture?: string;
  name: string;
  setUserToShareWith: (user: any) => void;
  selectedUsers: any;
  planId: number;
}

const UserSelectCard = ({
  id,
  planId,
  profilePicture,
  name,
  setUserToShareWith,
  selectedUsers,
}: UserSelectCardProps) => {
  const [selectedRole, setSelectedRole] = useState('viewer');
  const [isUserSelected, setIsUserSelected] = useState(false);
  const [successMessage, setSuccessMessage] = useState(false);
  const { width: screenWidth = null } = useWindowSize();
  const isMobile = screenWidth && screenWidth < 950;
  const modalStyle = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    borderRadius: '10px',
    transform: 'translate(-50%, -50%)',
    width: isMobile ? '300px' : '500px',
    height: isMobile ? '180px' : '218px',

    paddingTop: '30px',
    paddingLeft: '20px',
    paddingRight: '20px',
    bgcolor: ' #fff',
  };
  const handleRoleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedRole(event.target.value);

    const userRole = {
      userId: id,
      role: event.target.value,
    };
    setUserToShareWith(userRole);
  };

  const [showPopup, setShowPopup] = useState(false);
  // const handleClose = () => {
  //   setShowPopup(false);
  // };
  const openPopup = () => {
    setShowPopup(true);
  };
  const confirmSelection = async () => {
    const userRole = {
      userId: id,
      role: selectedRole,
    };
    const result = await shareActionPlan(planId, parseInt(id), selectedRole);
    if (result) setSuccessMessage(true);
    setUserToShareWith(userRole);
    setShowPopup(false);
  };

  useEffect(() => {
    const checkIfUserIsSelected = selectedUsers?.some(
      (user: { userId: string }) => user.userId === id
    );
    setIsUserSelected(checkIfUserIsSelected);
  }, [selectedUsers, id]);

  return (
    <div className="select-mentor-action-plan">
      <div className="select-user">
        <Checkbox checked={isUserSelected} onClick={openPopup} />
        <img
          src={profilePicture || 'avatar.png'}
          alt="User avatar"
          className="new-mentor-select-card__photo"
        />
        <div className="new-mentor-select-card__name">{name}</div>
      </div>
      {successMessage ? (
        <div className="actionplan-tab-text">
          {' '}
          <img src="/check_success.svg" alt="Success icon" />
          Action plan shared
        </div>
      ) : null}
      <Modal open={showPopup} onClose={() => setShowPopup(false)}>
        <Box sx={modalStyle}>
          <div className="permission-popup-action-plan">
            Set the permission for this user
            <RadioGroup
              aria-labelledby="demo-radio-buttons-group-label"
              value={selectedRole}
              onChange={handleRoleChange}
              name="radio-buttons-group"
              className="User-select-permission"
              style={{ flexDirection: 'row' }}
            >
              <FormControlLabel
                value="viewer"
                control={<Radio />}
                label={
                  <CustomTypographyPermission sx={{ fontFamily:'Gilroy'}}>
                   Viewer
                  </CustomTypographyPermission>
                }
                sx={{ width: '100px' }}
              />
              <FormControlLabel
                value="editor"
                control={<Radio />}
                label={
                  <CustomTypographyPermission sx={{ fontFamily:'Gilroy'}}>
                   Editor
                  </CustomTypographyPermission>
                }
              
                sx={{ width: '100px' }}
              />
            </RadioGroup>
            <div className="permission-buttons-wrapper">
              <DashboardFilledButton
                variant="contained"
                sx={{ width: isMobile ? '100px' : '189px' }}
                onClick={confirmSelection}
              >
                Confirm
              </DashboardFilledButton>
              <DashboardEmptyButton
                onClick={() => setShowPopup(false)}
                variant="outlined"
                sx={{ width: isMobile ? '100px' : '189px' }}
              >
                Cancel
              </DashboardEmptyButton>
            </div>
          </div>
        </Box>
      </Modal>
    </div>
  );
};

export default UserSelectCard;
