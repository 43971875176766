import React from 'react';
import {
  IconButton,
  Menu,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Checkbox,
} from '@mui/material';
import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined';
import { CheckboxCustomTypography } from 'styling/generalStyling';
import './style.scss';

interface FilterFilesProps {
  files: any[]; // Adjust the type according to your data structure for files
  checked: string | null; // Allow only one checked value at a time
  onFilterChange: (value: string | null) => void; // Callback to handle filter changes
}

const FilterFiles = ({ files, checked, onFilterChange }: FilterFilesProps) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleToggle = (label: string) => () => {
    // Toggle the checked state based on the currently checked value
    onFilterChange(checked === label ? null : label);
    setAnchorEl(null); // Close the menu
  };

  return (
    <div className="sort-library">
      Filter
      <IconButton
        sx={{ color: '#333', padding: 0,marginLeft:'5px' }}
        aria-controls={open ? 'basic-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
      >
        <FilterAltOutlinedIcon sx={{ color: '#333' }} />
      </IconButton>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        <div className="filter-menu-title">Filter by</div>
        <List sx={{ padding: 0 }}>
          {['All', 'Received', 'Uploaded'].map((label) => {
            const labelId = `checkbox-list-label-${label}`;

            return (
              <ListItem
                sx={{ marginBottom: '-15px', padding: 0 }}
                key={label}
                disablePadding
              >
                <ListItemButton
                  sx={{ paddingLeft: 0 }}
                  role={undefined}
                  onClick={handleToggle(label)}
                  dense
                >
                  <ListItemIcon>
                    <Checkbox
                      color="primary"
                      checked={checked === label}
                      tabIndex={-1}
                      disableRipple
                      inputProps={{ 'aria-labelledby': labelId }}
                    />
                  </ListItemIcon>
                  <ListItemText
                    id={labelId}
                    primary={
                      <CheckboxCustomTypography>
                        {label}
                      </CheckboxCustomTypography>
                    }
                  />
                </ListItemButton>
              </ListItem>
            );
          })}
        </List>
      </Menu>
    </div>
  );
};

export default FilterFiles;
